import {useContext, useEffect, useState} from "react";
import {DataBaseContext} from "../../context/DataBaseProvider";
import useDate from "../../hooks/useDate";
// import doctorDefaultPhoto from "../../assets/images/man_doctor_profile.webp";
import doctorDefaultPhoto from "../../assets/images/woman_doctor_default.webp";

const findObjectById = (array, id) => {
    return array.find(item => item.doctor_id === id);
};

export default function HistoryAppointmentItem({time, date, doctor_id, index, subIndex, service_id, status}) {
    const {monthsUkrainian} = useDate();
    const {ServicesList, DoctorsList} = useContext(DataBaseContext)

    // const [service, setService] = useState({});
    const [doctor, setDoctor] = useState({});
    const [month, setMonth] = useState("");
    const [day, setDay] = useState("");



    useEffect(() => {
        // const foundService = findObjectById(ServicesList, service_id);
        // if (foundService) {
        //     setService(foundService);
        // }

        const foundDoctor = findObjectById(DoctorsList, doctor_id);
        if (foundDoctor) {
            setDoctor(foundDoctor);
        }
    }, [DoctorsList, ServicesList, doctor_id, service_id]);

    const getMonthName = (day, month, year) => {
        const options = { month: 'long', locale: 'uk-UA' };
        ; // Split the string and convert each part to a number
        const date = new Date(year, month - 1, day); // Month is zero-based in JavaScript Date constructor
        return date.toLocaleDateString('uk-UA', options);
    };

    useEffect(() => {
        const [year, month, day] = date.split('-').map(Number)
        const result = getMonthName(day, month, year);
        setMonth(result !== "Invalid Date" ? result : "");
        setDay(day);
    }, [date]);
    return (
        <div style={{
            height: 108, borderRadius: 16, padding: 12, backgroundColor: "#fff", marginBottom: 16, position: "relative",
            borderTop: "6px solid", borderTopColor:  status === "ВІДВІДАВ" ? "#0072BA" : "#979C9F"
        }} key={index + " " + subIndex}>
            <div className="d-flex justify-content-end align-items-center">
            {/*<div className="d-flex justify-content-between align-items-center">*/}
            {/*    <p style={{*/}
            {/*        fontFamily: "Nunito", fontSize: 20, fontWeight: 700, color: "#000000", padding: 0, margin: 0*/}
            {/*    }}>Імплантація</p>*/}
                <button style={{
                    borderRadius: 12, padding: "3px 8px 3px 8px", backgroundColor: status === "ВІДВІДАВ" ? "#0072BA" : "#979C9F",
                    color: "#fff", fontFamily: "Nunito", fontWeight: "normal", fontSize: 12, border: "none", height: 24
                }}>
                    {status}
                    {/*{subIndex % 2 === 1 ? "Завершено" : "Скасовано"}*/}
                </button>
            </div>
            <div className="d-flex" style={{
                marginTop: 4, paddingBottom: 100
            }}>
                <img src={doctorDefaultPhoto} alt="" style={{
                    width: 48, height: 48, objectFit: "cover", borderRadius: "50%"
                }}/>
                <div style={{
                    paddingLeft: 8
                }}>
                    <p style={{
                        fontFamily: "Nunito", fontSize: 14, fontWeight: "normal", color: "#000",
                        whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "100%",
                        margin: 0, padding: 0
                    }}>
                        {doctor.surname + " " + doctor.name}
                        {/*{doctor.lastname + " " + doctor.firstname} // CLinicCards*/}
                    </p>
                    <p style={{
                        fontFamily: "Nunito", fontSize: 12, fontWeight: "normal", color: "#313131",
                        whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "100%",
                        margin: 0, padding: 0
                    }}>{month.charAt(0).toUpperCase() + month.slice(1) + " " + day + ", " + time}</p>
                </div>
            </div>
        </div>
    );
}
