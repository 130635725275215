export default function useDate(){
    const monthsUkrainian = [
        "Січень",   // January
        "Лютий",    // February
        "Березень", // March
        "Квітень",  // April
        "Травень",  // May
        "Червень",  // June
        "Липень",   // July
        "Серпень",  // August
        "Вересень", // September
        "Жовтень",  // October
        "Листопад", // November
        "Грудень"   // December
    ];

    const weekDays = [
        "Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Нд"
    ];

    const getCurrentDate = () => {
        const currentDate = new Date();

        const day = currentDate.getDate(); // Get the day of the month (1-31)
        const month = currentDate.getMonth() + 1; // Get the month (0-11, so we add 1 for 1-12)
        const year = currentDate.getFullYear(); // Get the full year (e.g., 2024)

        return [day, month, year];
    };

    return {
        monthsUkrainian, getCurrentDate, weekDays
    }
}