export default function useTelegram(){
    const tg = window.Telegram.WebApp;

    const initDataUnsafe = tg.initDataUnsafe;

    const onClose = () => {
        tg.close();
    }

    const handleMainButtonText = (text) => {
        tg.MainButton.text = text;
    }

    const ready = () => {
        tg.ready();
    }

    const isTelegramVersion = () => {
        return tg.version > '6.1';
    };

    const isTelegram = isTelegramVersion();

    const telegramPlatform = tg.platform;

    const expand = () => {
        tg.expand();
    }
    return {
        tg,
        onClose,
        handleMainButtonText,
        ready,
        expand,
        isTelegram,
        telegramPlatform,
        initDataUnsafe
    }
}