import {useContext, useEffect, useRef, useState} from "react";
import {DataBaseContext} from "../../context/DataBaseProvider";
import ProfileImage from "../../assets/images/VladyslavBulatov.jpeg";
import {NavigationContext} from "../../context/NavigationContext";
import useTelegram from "../../hooks/useTelegram";

function useInput(initialValue) {
    const [value, setValue] = useState(initialValue);
    const handleChange = (e) => {
        if (/^[a-zA-Z]+$/.test(e.target.value) && e.target.value.length < 55) {
            setValue(e.target.value);
        }
    };
    return [value, handleChange];
}

export default function Settings() {
    const {profileData} = useContext(DataBaseContext);
    const {handleChangeCurrentPage} = useContext(NavigationContext);
    const {tg, handleMainButtonText} = useTelegram();

    const goBack = () => {
        handleChangeCurrentPage("profile");
    };

    useEffect(() =>{
        tg.BackButton.show();

        tg.BackButton.onClick(goBack);
        return () => {
            tg.BackButton.offClick(goBack);
        }
    }, []);

    useEffect(() => {
        handleMainButtonText("Зберегти");
        tg.MainButton.show();

        tg.MainButton.onClick(handleSave);
        return () => {
            tg.MainButton.offClick(handleSave);
        }
    }, []);

    const [firstName, handleFirstNameChange] = useInput(profileData.first_name);
    const [lastName, handleLastNameChange] = useInput(profileData.last_name);
    const [dateOfBirth, setDateOfBirth] = useState(profileData.date_of_birth);
    const [phoneNumber, setPhoneNumber] = useState(profileData.phone_number);
    const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState(profileData.emergency_phone_number);
    const [image, setImage] = useState(ProfileImage);

    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
    const [isValidEmergencyPhoneNumber, setIsValidEmergencyPhoneNumber] = useState(true);
    const [isValidDateOfBirth, setIsValidDateOfBirth] = useState(true);

    const handleEmergencyPhoneNumber = (e) => {
        const inputPhoneNumber = e.target.value;

        const isValidFormat = /^\d{10}$/.test(inputPhoneNumber);
        setIsValidEmergencyPhoneNumber(isValidFormat);

        setEmergencyPhoneNumber(inputPhoneNumber);
    };

    const handlePhoneNumber = (e) => {
        const inputPhoneNumber = e.target.value;

        const isValidFormat = /^\d{10}$/.test(inputPhoneNumber);
        setIsValidPhoneNumber(isValidFormat);

        setPhoneNumber(inputPhoneNumber);
    };

    function isValidDate(inputDate) {
        const minDate = new Date('1910-01-01');

        const parsedDate = new Date(inputDate);

        return parsedDate >= minDate;
    }
    const handleDateOfBirthChange = (e) => {
        const inputDateOfBirth = e.target.value;

        const isValidFormat = isValidDate(inputDateOfBirth);
        setIsValidDateOfBirth(isValidFormat);

        setDateOfBirth(inputDateOfBirth);
    };
    const fileInputRef = useRef(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const allowedFormats = ['image/png', 'image/jpeg', 'image/jpg', 'image/heic'];
            if (allowedFormats.includes(file.type)) {
                const url = URL.createObjectURL(file);
                setImage(url);
            } else {
                // File format is not allowed
                console.log('File format is not allowed:', file.name);
            }
        }
    };

    const handleImageChange = () => {
        fileInputRef.current.click();
    };
    const handleSave = () => {
        if (!isValidDateOfBirth) {
            return;
        }
        if (!isValidPhoneNumber) {
            return;
        }
        if (!isValidPhoneNumber) {
            return;
        }
        const updatedProfileData = {
            ...profileData,
            first_name: firstName,
            last_name: lastName,
            date_of_birth: dateOfBirth,
            phone_number: phoneNumber
        };
        console.log(updatedProfileData);
    };

    const styles = [
        {
            backgroundColor: "#4d8ac0",
            fontWeight: "500",
            fontSize: 22,
            color: "rgba(255,255,255,0.8)",
            width: "100%",
            position: "fixed",
            bottom: 0,
            left: 0,
            zIndex: 5,
            height: "100px"
        },
        {
            borderRadius: "15rem",
        },
        {
            width: 50,
            height: 50,
            backgroundColor: "rgb(120, 198, 246)",
            borderRadius: "15rem"
        }
    ];

    
    return (
        <main className="container" style={{overflowY: 'auto', paddingBottom: "120px"}}>
            <h1>Налаштування</h1>
            <div className="d-flex align-items-end">
                <img src={image} alt="" width={150} height={150} style={styles[1]} />
                <div style={styles[2]} className="d-flex justify-content-center align-items-center" onClick={handleImageChange}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="white" className="bi bi-camera" viewBox="0 0 16 16">
                        <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4z"/>
                        <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5m0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0"/>
                    </svg>
                </div>
                <input
                    type="file"
                    accept=".png,.jpeg,.jpg,.heic"
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={handleFileChange}
                />
            </div>
            <div className="d-flex" style={{marginTop: 26}}>
                <p style={{flexGrow: 1, fontSize: 16}}>Імʼя</p>
                <input type="text" value={firstName.toString()} onChange={handleFirstNameChange} style={{width: "180px", height: "40px"}} required/>
            </div>
            <div className="d-flex" style={{marginTop: 26}}>
                <p style={{flexGrow: 1, fontSize: 16}}>Прізвище</p>
                <input type="text" value={lastName.toString()} onChange={handleLastNameChange} style={{width: "180px", height: "40px"}} required/>
            </div>
            <div className="d-flex" style={{marginTop: 26}}>
                <p style={{flexGrow: 1, fontSize: 16}}>Дата народження</p>
                <input type="date" value={dateOfBirth.toString()} onChange={handleDateOfBirthChange}
                       style={{border: isValidDateOfBirth ? '1px solid black' : '1px solid red', width: "180px", height: "40px"}} required/>
            </div>
            {!isValidDateOfBirth && (
                <p style={{ color: 'red', marginTop: 5 }}>Please enter a valid date of your Birthday</p>
            )}
            <div className="d-flex" style={{marginTop: 26}}>
                <p style={{flexGrow: 1, fontSize: 16}}>Номер телефону</p>
                <input type="tel" value={phoneNumber.toString()} pattern="[0-9]{10}" onChange={handlePhoneNumber} required
                       style={{ border: isValidPhoneNumber ? '1px solid black' : '1px solid red', width: "180px", height: "40px"}}/>
            </div>
            {!isValidPhoneNumber && (
                <p style={{ color: 'red', marginTop: 5 }}>Please enter a valid 10-digit phone number</p>
            )}
            <div className="d-flex" style={{marginTop: 26}}>
                <p style={{flexGrow: 1, fontSize: 16}}>Номер телефону контактної особи</p>
                <input type="tel" value={emergencyPhoneNumber.toString()} pattern="[0-9]{10}" onChange={handleEmergencyPhoneNumber} required
                       style={{ border: isValidEmergencyPhoneNumber ? '1px solid black' : '1px solid red', width: "180px", height: "40px"}}/>
            </div>
            {!isValidEmergencyPhoneNumber && (
                <p style={{ color: 'red', marginTop: 5 }}>Please enter a valid 10-digit phone number</p>
            )}
            {/*<button style={styles[0]} onClick={handleSave}>Запис</button>*/}
        </main>
    )
}