export default function ArticleItem({article}) {
    return (
        <div style={{
            backgroundColor: "#fff", width: "100%", borderRadius: 16, padding: 16, marginBottom: 16
        }}>
            <div className="d-flex justify-content-between" style={{
                borderBottom: "1px solid #CAC4D0", marginBottom: 8
            }}>
                <p style={{
                     fontFamily: "Nunito", fontWeight: 600, fontSize: 16, color: "#000"
                }}>{article.title}</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 18 17" fill="none">
                    <path d="M5.85002 13.825L9.00002 11.925L12.15 13.85L11.325 10.25L14.1 7.84995L10.45 7.52495L9.00002 4.12495L7.55002 7.49995L3.90001 7.82495L6.67501 10.25L5.85002 13.825ZM9.00002 14.275L4.85002 16.775C4.66668 16.8916 4.47502 16.9416 4.27501 16.9249C4.07501 16.9083 3.90001 16.8416 3.75002 16.725C3.60001 16.6083 3.48335 16.4625 3.40002 16.2874C3.31668 16.1125 3.30002 15.9166 3.35002 15.7L4.45002 10.975L0.775015 7.79995C0.608348 7.64995 0.504182 7.47912 0.462515 7.28745C0.420848 7.09578 0.433348 6.90828 0.500015 6.72495C0.566682 6.54162 0.666682 6.39162 0.800015 6.27495C0.933348 6.15828 1.11668 6.08328 1.35002 6.04995L6.20002 5.62495L8.07502 1.17495C8.15835 0.974951 8.28752 0.824951 8.46252 0.724951C8.63752 0.624951 8.81668 0.574951 9.00002 0.574951C9.18335 0.574951 9.36252 0.624951 9.53752 0.724951C9.71252 0.824951 9.84168 0.974951 9.92502 1.17495L11.8 5.62495L16.65 6.04995C16.8833 6.08328 17.0667 6.15828 17.2 6.27495C17.3333 6.39162 17.4333 6.54162 17.5 6.72495C17.5667 6.90828 17.5792 7.09578 17.5375 7.28745C17.4958 7.47912 17.3917 7.64995 17.225 7.79995L13.55 10.975L14.65 15.7C14.7 15.9166 14.6833 16.1125 14.6 16.2874C14.5167 16.4625 14.4 16.6083 14.25 16.725C14.1 16.8416 13.925 16.9083 13.725 16.9249C13.525 16.9416 13.3333 16.8916 13.15 16.775L9.00002 14.275Z" fill="#313131"/>
                </svg>
            </div>
            <div className="row">
                <div className="col-3" style={{
                    padding: "0 0 0 8px"
                }}>
                    <div style={{
                        width: "100%", paddingTop: "100%", position: "relative", zIndex: 2
                    }}>
                        <img src={article.image} alt="" style={{
                            width: "100%", height: "100%", objectFit: "cover", borderRadius: 24,  objectPosition: "center", position: "absolute", top: 0, zIndex: 1
                        }}/>
                    </div>
                </div>
                <div className="col-9">
                    <p style={{
                        margin: "0.5em 0", fontFamily: "Nunito", fontWeight: "normal", fontSize: 14, color: "#000", whiteSpace: "nowrap"
                    }}>{article.category.map((category, index) => {
                        return index === 0 ? category : ` · ${category} `
                    })}</p>
                    <p style={{
                        margin: 0, fontFamily: "Nunito", fontWeight: "normal", fontSize: 12, color: "#313131", maxHeight: "5.2em", overflow: "hidden"
                    }}>{article.description}</p>
                </div>
            </div>
        </div>
    )
}