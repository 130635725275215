export default function NotificationItem({item, key}) {
    return (
        <div style={{
            backgroundColor: "#fff", boxShadow: "0px 14px 14px 0px #0000000D", width: "100%",
            padding: "12px 16px 12px 16px", borderRadius: 8, margin: "16px 0 16px 0", height: 56
        }} className="d-flex justify-content-between align-items-center">
            <div className="col-2 d-flex justify-content-center align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-0-circle" viewBox="0 0 16 16">
                    <path d="M7.988 12.158c-1.851 0-2.941-1.57-2.941-3.99V7.84c0-2.408 1.101-3.996 2.965-3.996 1.857 0 2.935 1.57 2.935 3.996v.328c0 2.408-1.101 3.99-2.959 3.99M8 4.951c-1.008 0-1.629 1.09-1.629 2.895v.31c0 1.81.627 2.895 1.629 2.895s1.623-1.09 1.623-2.895v-.31c0-1.8-.621-2.895-1.623-2.895"/>
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8"/>
                </svg>
            </div>
            <div className="col-6 d-flex align-items-center" style={{fontSize: 14}}>
                {item.title}
            </div>
            <div className="col-4 align-items-center" style={{fontSize: 12, textAlign: "end"}}>
                4 квітня 2024
            </div>
        </div>
        // <div className="row" style={{width: "100%", height: "48px"}}>
        //     <div className="col-2 d-flex justify-content-center align-items-center">
        //         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-0-circle" viewBox="0 0 16 16">
        //             <path d="M7.988 12.158c-1.851 0-2.941-1.57-2.941-3.99V7.84c0-2.408 1.101-3.996 2.965-3.996 1.857 0 2.935 1.57 2.935 3.996v.328c0 2.408-1.101 3.99-2.959 3.99M8 4.951c-1.008 0-1.629 1.09-1.629 2.895v.31c0 1.81.627 2.895 1.629 2.895s1.623-1.09 1.623-2.895v-.31c0-1.8-.621-2.895-1.623-2.895"/>
        //             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8"/>
        //         </svg>
        //     </div>
        //     <div className="col-6 d-flex align-items-center" style={{fontSize: 16}}>
        //         {item.title}
        //     </div>
        //     <div className="col-4 d-flex align-items-start" style={{fontSize: 12}}>
        //         4 квітня 2024
        //     </div>
        // </div>
    )
}