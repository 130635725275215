import {createContext, useEffect, useState} from "react";
import useTelegram from "../hooks/useTelegram";
import axios from 'axios';

const DataBaseContext = createContext(null);

const DataBaseProvider = (props) => {
    const [status, setStatus] = useState(false);
    const {initDataUnsafe} = useTelegram();

    const tgUserData = {
        username: initDataUnsafe?.user.username,
        tgUserId: initDataUnsafe?.user.id,
        // userPhotoUrl: `https://api.telegram.org/file/bot6700813939:AAGCswAQxIDqbha1OshpKogQHb2EUi8qDkk/${initDataUnsafe?.user.photo_url}`
    };

    // const tgUserData = {
    //     username: "ed475",
    //     tgUserId: "474703892",
    //     // tgUserId: "123456789",
    //     userPhotoUrl: "https://api.telegram.org/file/bot6700813939:AAGCswAQxIDqbha1OshpKogQHb2EUi8qDkk/photos/file_0.jpg"
    // }

    //

    // useEffect(() => {
        // get telegram user id from cliniccards crm
        // const telegramUserId = {
        //     "tgUserId": "123456789"
        // };
        //
        // sendAPIrequest("getPatientDataByTelegramUserId", JSON.stringify(telegramUserId))
        // sendAPIrequest("getPatients");

        // const createPatientData = {
        //     "lastname": "John",
        //     "firstname": "Doe",
        //     "phone": "380993334455",
        //     "phone2": "",
        //     "discount": "5",
        //     "address": "Some Street 3",
        //     "email": "johndoe@gmail.com",
        //     "gender": "M",
        //     "important_note": "",
        //     "note": "tg_chat_id: 123123123, tg_username: ed475, tg_user_id: 123456789",
        //     "birthday": "1980-01-01",
        //     "preferred_contact": "PHONE",
        // };
        // sendAPIrequest("createPatient", JSON.stringify(createPatientData));
        // sendAPIrequest("getStages")
    // }, []);

    // const sendAPIrequest = async (action, data = "") => {
    //     const formData = new FormData();
    //     formData.append('action', action);
    //     formData.append('data', data);
    //
    //     const requestOptions = {
    //         method: 'POST',
    //         body: formData,
    //         redirect: 'follow'
    //     };
    //
    //     try {
    //         const response = await fetch('https://generatepdfeasy.eu/main.php', requestOptions);
    //         const result = await response.json();
    //         console.log(result);
    //         return result;
    //
    //     } catch (error) {
    //         console.log('error', error);
    //         return "";
    //     }
    // };

    // const profileDataa = sendAPIrequest("getPatientDataByTelegramUserId", JSON.stringify(tgUserData));
    // if (profileDataa.status === "error") {
    //     setStatus(false);
    // } else {
    //     setStatus(true);
    // }

    // const getPatientTelegramUserId = async () => {
    //     const formData = new FormData();
    //     formData.append('action', "getPatients");
    //     const requestOptions = {
    //         method: 'POST',
    //         body: formData,
    //         redirect: 'follow'
    //     };
    //
    //     try {
    //         const response = await fetch('http://localhost:8000/main.php', requestOptions);
    //         const result = await response.json();
    //         result['data'].map(item => {
    //             const match = item.note.match(/tg_user_id:\s*(\d+)/);
    //             if (match) {
    //                 console.log(match[1]);
    //             }
    //         })
    //
    //     } catch (error) {
    //         console.log('error', error);
    //     }
    //
    // }
    const ServicesList = [
        {
            id: "0",
            title: "Лікування зубів",
            price: 1000,
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
            id: "1",
            title: "Протезування",
            price: 8800,
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
            id: "2",
            title: "Хірургія",
            price: 200,
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
            id: "3",
            title: "Імплантація",
            price: 500,
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
            id: "4",
            title: "Ортодонтія",
            price: 500,
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
            id: "5",
            title: "Дитяча стоматологія",
            price: 500,
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
    ];

    const DoctorsList = [
        {
            id: "0",
            name: "Дарʼя",
            surname: "Малинівська",
            fatherName: "Сергіївна",
            position: "Ортодонт, Хірург",
            image: "https://img.freepik.com/free-photo/portrait-beautiful-young-woman-standing-grey-wall_231208-10760.jpg?w=1800&t=st=1711476155~exp=1711476755~hmac=4caf9b7a33ccb3c80075c553d76342bd3d4cede634152114633467f9520081dc"
        },
        {
            id: "1",
            name: "Олександр",
            surname: "Просяник",
            fatherName: "Олександрович",
            position: "Дитячій лікар",
            image: "https://st.depositphotos.com/2309453/2658/i/450/depositphotos_26584765-stock-photo-portrait-of-serious-man-against.jpg"
        },
        {
            id: "2",
            name: "Анжела",
            surname: "Лук'яненко",
            fatherName: "Вікторівна",
            position: "Ортопед, Імплантолог",
            image: "https://img.freepik.com/free-photo/portrait-cheerful-woman-laughing-smiling-cross-arms-chest-standing-confident-power-pose_1258-190413.jpg?w=1800&t=st=1711476241~exp=1711476841~hmac=1d68d46f7c51a260adff2a5770ab9dff54622629ac17041f19bab8943c1d0886"
        },
        {
            id: "3",
            name: "Ігор",
            surname: "Россіпчук",
            fatherName: "Юрійович",
            position: "Хірург",
            image: "https://t4.ftcdn.net/jpg/02/14/74/61/360_F_214746128_31JkeaP6rU0NzzzdFC4khGkmqc8noe6h.jpg"
        },
    ];

    const [favoriteServices, setFavoriteServices] = useState([]);

    const handleFavoriteServices = (id) => {
        const updatedFavorites = new Set(favoriteServices);

        if (updatedFavorites.has(id)) {
            updatedFavorites.delete(id);
        } else {
            updatedFavorites.add(id);
        }
        setFavoriteServices([...updatedFavorites]);
    };

    const [favoriteDoctors, setFavoriteDoctors] = useState([]);

    const handleFavoriteDoctors = (id) => {
        const updatedFavorites = new Set(favoriteDoctors);

        if (updatedFavorites.has(id)) {
            updatedFavorites.delete(id);
        } else {
            updatedFavorites.add(id);
        }
        setFavoriteDoctors([...updatedFavorites]);
    };

    const inititalProfileData = {
        image: "../../assets/images/VladyslavBulatov.jpeg",
        first_name: "Галина",
        last_name: "Янченко",
        date_of_birth: "1980-06-25",
        phone_number: "0967015561",
        emergency_phone_number: "0967015561",
        bills: [
            "0",
            "1",
            "2"
        ],
        tooth_status: {
            // 0 Transparent
            // 1 Yellow
            // 2 Grey
            // 3 Red
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
            13: 0,
            14: 0,
            15: 0,
            16: 0,
            17: 0,
            18: 0,
            19: 0,
            20: 0,
            21: 0,
            22: 0,
            23: 0,
            24: 0,
            25: 0,
            26: 0,
            27: 0,
            28: 0,
            29: 0,
            30: 0,
            31: 0,
            32: 0
        },
        appointments: [
            {
                "visit_id": "visit_id_1",
                "patient_id": "1111111",
                "status": "PLANNED",
                "doctor": "Doctor 2",
                "doctor_id": "2",
                "date_created": "2024-10-01 11:11:13",
                "date_edited": "2024-10-01 11:11:13",
                "visit_start": "2024-10-26 10:45:00",
                "visit_end": "2024-10-26 11:45:00",
                "created_by": "Administrator",
                "created_by_id": "50000",
                "edited_by": "Administrator",
                "edited_by_id": "50000",
                "note": "Visit created by API",
                "cabinet": "Office 1",
                "cabinet_id": "15000"
            },
            {
                "visit_id": "visit_id_2",
                "patient_id": "1111111",
                "status": "PLANNED",
                "doctor": "Doctor 3",
                "doctor_id": "3",
                "date_created": "2024-10-01 11:11:13",
                "date_edited": "2024-10-01 11:11:13",
                "visit_start": "2024-10-26 11:30:00",
                "visit_end": "2024-10-26 12:30:00",
                "created_by": "Administrator",
                "created_by_id": "50000",
                "edited_by": "Administrator",
                "edited_by_id": "50000",
                "note": "Visit created by API",
                "cabinet": "Office 1",
                "cabinet_id": "15000"
            },
            {
                "visit_id": "visit_id_3",
                "patient_id": "1111111",
                "status": "PLANNED",
                "doctor": "Doctor 1",
                "doctor_id": "1",
                "date_created": "2024-10-01 11:11:13",
                "date_edited": "2024-10-01 11:11:13",
                "visit_start": "2024-10-15 09:30:00",
                "visit_end": "2024-10-15 10:30:00",
                "created_by": "Administrator",
                "created_by_id": "50000",
                "edited_by": "Administrator",
                "edited_by_id": "50000",
                "note": "Visit created by API",
                "cabinet": "Office 1",
                "cabinet_id": "15000"
            },
            {
                "visit_id": "visit_id_4",
                "patient_id": "1111111",
                "status": "PLANNED",
                "doctor": "Doctor 3",
                "doctor_id": "3",
                "date_created": "2024-10-01 11:11:13",
                "date_edited": "2024-10-01 11:11:13",
                "visit_start": "2024-10-15 08:30:00",
                "visit_end": "2024-10-15 09:30:00",
                "created_by": "Administrator",
                "created_by_id": "50000",
                "edited_by": "Administrator",
                "edited_by_id": "50000",
                "note": "Visit created by API",
                "cabinet": "Office 1",
                "cabinet_id": "15000"
            },
            {
                "visit_id": "visit_id_5",
                "patient_id": "1111111",
                "status": "PLANNED",
                "doctor": "Doctor 2",
                "doctor_id": "2",
                "date_created": "2024-10-01 11:11:13",
                "date_edited": "2024-10-01 11:11:13",
                "visit_start": "2024-10-10 10:15:00",
                "visit_end": "2024-10-10 11:15:00",
                "created_by": "Administrator",
                "created_by_id": "50000",
                "edited_by": "Administrator",
                "edited_by_id": "50000",
                "note": "Visit created by API",
                "cabinet": "Office 1",
                "cabinet_id": "15000"
            },
            {
                "visit_id": "visit_id_6",
                "patient_id": "1111111",
                "status": "PLANNED",
                "doctor": "Doctor 3",
                "doctor_id": "3",
                "date_created": "2024-10-01 11:11:13",
                "date_edited": "2024-10-01 11:11:13",
                "visit_start": "2024-10-10 09:30:00",
                "visit_end": "2024-10-10 10:30:00",
                "created_by": "Administrator",
                "created_by_id": "50000",
                "edited_by": "Administrator",
                "edited_by_id": "50000",
                "note": "Visit created by API",
                "cabinet": "Office 1",
                "cabinet_id": "15000"
            },
            {
                "visit_id": "visit_id_7",
                "patient_id": "1111111",
                "status": "PLANNED",
                "doctor": "Doctor 3",
                "doctor_id": "3",
                "date_created": "2024-10-01 11:11:13",
                "date_edited": "2024-10-01 11:11:13",
                "visit_start": "2024-10-23 12:15:00",
                "visit_end": "2024-10-23 13:15:00",
                "created_by": "Administrator",
                "created_by_id": "50000",
                "edited_by": "Administrator",
                "edited_by_id": "50000",
                "note": "Visit created by API",
                "cabinet": "Office 1",
                "cabinet_id": "15000"
            },
            {
                "visit_id": "visit_id_8",
                "patient_id": "1111111",
                "status": "PLANNED",
                "doctor": "Doctor 2",
                "doctor_id": "2",
                "date_created": "2024-10-01 11:11:13",
                "date_edited": "2024-10-01 11:11:13",
                "visit_start": "2024-10-23 11:30:00",
                "visit_end": "2024-10-23 12:30:00",
                "created_by": "Administrator",
                "created_by_id": "50000",
                "edited_by": "Administrator",
                "edited_by_id": "50000",
                "note": "Visit created by API",
                "cabinet": "Office 1",
                "cabinet_id": "15000"
            }
            // "26-10-2024": [
            //     {
            //         service_id: "4",
            //         doctor_id: "2",
            //         time: "10:45"
            //     },
            //     {
            //         service_id: "3",
            //         doctor_id: "3",
            //         time: "11:30"
            //     }
            // ],
            // "15-10-2024": [
            //     {
            //         service_id: "0",
            //         doctor_id: "1",
            //         time: "9:30"
            //     },
            //     {
            //         service_id: "5",
            //         doctor_id: "3",
            //         time: "8:30"
            //     }
            // ],
            // "10-10-2024": [
            //     {
            //         service_id: "4",
            //         doctor_id: "2",
            //         time: "10:15"
            //     },
            //     {
            //         service_id: "3",
            //         doctor_id: "3",
            //         time: "9:30"
            //     }
            // ],
            // "23-10-2024": [
            //     {
            //         service_id: "1",
            //         doctor_id: "3",
            //         time: "12:15"
            //     },
            //     {
            //         service_id: "2",
            //         doctor_id: "2",
            //         time: "11:30"
            //     }
            // ],
        ],
        form_id: "0"
    };

    const notifications = [
        {
            title: "Lorem Ipsum is simply 1",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            isVisible: "true"
        },
        {
            title: "Lorem Ipsum is simply 2",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            isVisible: "true"
        },
        {
            title: "Lorem Ipsum is simply 3",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            isVisible: "true"
        },
        {
            title: "Lorem Ipsum is simply 4",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            isVisible: "true"
        },
        {
            title: "Lorem Ipsum is simply 5",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            isVisible: "true"
        },
        {
            title: "Lorem Ipsum is simply 6",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            isVisible: "true"
        },
        {
            title: "Lorem Ipsum is simply 7",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            isVisible: "true"
        },
        {
            title: "Lorem Ipsum is simply 8",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            isVisible: "true"
        },
        {
            title: "Lorem Ipsum is simply 9",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            isVisible: "true"
        },
        {
            title: "Lorem Ipsum is simply 10",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            isVisible: "true"
        },
        {
            title: "Lorem Ipsum is simply 11",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            isVisible: "true"
        },
        {
            title: "Lorem Ipsum is simply 12",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            isVisible: "true"
        },
    ]

    const [profileData, setProfileData] = useState(inititalProfileData);
    // const [DoctorsList, setDoctorList] = useState([]);

    useEffect (() => {
        // const getPatientData = async () => {
        //     try {
        //         const result = await sendAPIRequest("getPatientDataByTelegramUserId", JSON.stringify(tgUserData));
        //         console.log('Result:', result);
        //         if (result && result.status === "success") {
        //             setProfileData(result.message);
        //         }
        //     } catch (error) {
        //         console.error('Request failed', error);
        //     }
        // };
        //
        // getPatientData();
        //
        // const getStaffData = async () => {
        //     try {
        //         const result = await sendAPIRequest("getStaff");
        //         if (result && result.result === "success") {
        //             setDoctorList(result.data);
        //         }
        //     } catch (e) {
        //         console.error('Request failed', e);
        //     }
        // }
        //
        // getStaffData();
    }, []);

    return (
        <DataBaseContext.Provider
            value={{
                ServicesList, DoctorsList, favoriteServices, handleFavoriteServices, handleFavoriteDoctors, favoriteDoctors,
                profileData, notifications, status, tgUserData
            }}>
            {props.children}
        </DataBaseContext.Provider>
    );
}

export {DataBaseProvider, DataBaseContext}

// 1)
//     https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/getUserProfilePhotos?user_id=${user_id}
//
//     Result: result.photos[0][2].file_id
//     "AgACAgIAAxUAAWaSTbWJIRMIRNjQoJX283wZeFQ7AAKwpzEbFGhLHCXNoGlYbCOkAQADAgADYwADNQQ"
// 2)
//     https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/getFile?file_id=${photo_url}
//
//     Result: result.file_path
//     "photos/file_0.jpg"
