import "./footerTest.css";

export default function Footer({activeIndex, handleItemClick}) {
    // const {handleChangeCurrentPage} = useContext(NavigationContext);
    const sections = [
        {
            title: "",
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                    <path d="M8.3 25.3334H12.3V18.6667C12.3 18.289 12.4278 17.9723 12.6833 17.7167C12.9389 17.4612 13.2556 17.3334 13.6333 17.3334H18.9667C19.3444 17.3334 19.6611 17.4612 19.9167 17.7167C20.1722 17.9723 20.3 18.289 20.3 18.6667V25.3334H24.3V13.3334L16.3 7.33341L8.3 13.3334V25.3334ZM5.63333 25.3334V13.3334C5.63333 12.9112 5.72778 12.5112 5.91666 12.1334C6.10555 11.7556 6.36666 11.4445 6.7 11.2001L14.7 5.20008C15.1667 4.84453 15.7 4.66675 16.3 4.66675C16.9 4.66675 17.4333 4.84453 17.9 5.20008L25.9 11.2001C26.2333 11.4445 26.4944 11.7556 26.6833 12.1334C26.8722 12.5112 26.9667 12.9112 26.9667 13.3334V25.3334C26.9667 26.0667 26.7056 26.6945 26.1833 27.2167C25.6611 27.739 25.0333 28.0001 24.3 28.0001H18.9667C18.5889 28.0001 18.2722 27.8723 18.0167 27.6167C17.7611 27.3612 17.6333 27.0445 17.6333 26.6667V20.0001H14.9667V26.6667C14.9667 27.0445 14.8389 27.3612 14.5833 27.6167C14.3278 27.8723 14.0111 28.0001 13.6333 28.0001H8.3C7.56666 28.0001 6.93889 27.739 6.41666 27.2167C5.89444 26.6945 5.63333 26.0667 5.63333 25.3334Z" fill={activeIndex === 0 ? "#0072BA" : "#313131"}/>
                </svg>,
            description: "Головна"
        },
        {
            title: "appointment",
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                    <path d="M7.56663 25.3333H9.46663L22.5 12.3L20.6 10.4L7.56663 23.4333V25.3333ZM6.2333 28C5.85552 28 5.53885 27.8722 5.2833 27.6167C5.02774 27.3611 4.89996 27.0444 4.89996 26.6667V23.4333C4.89996 23.0778 4.96663 22.7389 5.09996 22.4167C5.2333 22.0944 5.42219 21.8111 5.66663 21.5667L22.5 4.76667C22.7666 4.52222 23.0611 4.33333 23.3833 4.2C23.7055 4.06667 24.0444 4 24.4 4C24.7555 4 25.1 4.06667 25.4333 4.2C25.7666 4.33333 26.0555 4.53333 26.3 4.8L28.1333 6.66667C28.4 6.91111 28.5944 7.2 28.7166 7.53333C28.8389 7.86667 28.9 8.2 28.9 8.53333C28.9 8.88889 28.8389 9.22778 28.7166 9.55C28.5944 9.87222 28.4 10.1667 28.1333 10.4333L11.3333 27.2333C11.0889 27.4778 10.8055 27.6667 10.4833 27.8C10.1611 27.9333 9.82219 28 9.46663 28H6.2333ZM21.5333 11.3667L20.6 10.4L22.5 12.3L21.5333 11.3667Z" fill={activeIndex === 1 ? "#0072BA" : "#313131"}/>
                </svg>,
            description: "Записи"
        },
        {
            title: "history",
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="23" height="28" viewBox="0 0 23 28" fill="none">
                    <path d="M3.50001 27.3334C2.76668 27.3334 2.1389 27.0723 1.61668 26.5501C1.09445 26.0279 0.833344 25.4001 0.833344 24.6667V3.33341C0.833344 2.60008 1.09445 1.9723 1.61668 1.45008C2.1389 0.927859 2.76668 0.666748 3.50001 0.666748H19.5C20.2333 0.666748 20.8611 0.927859 21.3833 1.45008C21.9056 1.9723 22.1667 2.60008 22.1667 3.33341V24.6667C22.1667 25.4001 21.9056 26.0279 21.3833 26.5501C20.8611 27.0723 20.2333 27.3334 19.5 27.3334H3.50001ZM3.50001 24.6667H19.5V3.33341H16.8333V11.5001C16.8333 11.7667 16.7222 11.9612 16.5 12.0834C16.2778 12.2056 16.0556 12.2001 15.8333 12.0667L14.2 11.0667C13.9778 10.9334 13.75 10.8667 13.5167 10.8667C13.2833 10.8667 13.0556 10.9334 12.8333 11.0667L11.2 12.0667C10.9778 12.2001 10.75 12.2056 10.5167 12.0834C10.2833 11.9612 10.1667 11.7667 10.1667 11.5001V3.33341H3.50001V24.6667Z" fill={activeIndex === 2 ? "#0072BA" : "#313131"}/>
                </svg>,
            description: "Сервіси"
        },
        // {
        //     title: "recommendation",
        //     icon: <svg xmlns="http://www.w3.org/2000/svg" width="28" height="22" viewBox="0 0 28 22" fill="none">
        //             <path d="M7.4333 12.3333H16.7666C17.1444 12.3333 17.4611 12.2055 17.7166 11.9499C17.9722 11.6944 18.1 11.3777 18.1 10.9999C18.1 10.6221 17.9722 10.3055 17.7166 10.0499C17.4611 9.79436 17.1444 9.66659 16.7666 9.66659H7.4333C7.05552 9.66659 6.73885 9.79436 6.4833 10.0499C6.22774 10.3055 6.09997 10.6221 6.09997 10.9999C6.09997 11.3777 6.22774 11.6944 6.4833 11.9499C6.73885 12.2055 7.05552 12.3333 7.4333 12.3333ZM7.4333 8.33325H16.7666C17.1444 8.33325 17.4611 8.20547 17.7166 7.94992C17.9722 7.69436 18.1 7.3777 18.1 6.99992C18.1 6.62214 17.9722 6.30547 17.7166 6.04992C17.4611 5.79436 17.1444 5.66659 16.7666 5.66659H7.4333C7.05552 5.66659 6.73885 5.79436 6.4833 6.04992C6.22774 6.30547 6.09997 6.62214 6.09997 6.99992C6.09997 7.3777 6.22774 7.69436 6.4833 7.94992C6.73885 8.20547 7.05552 8.33325 7.4333 8.33325ZM3.4333 21.6666C2.69997 21.6666 2.07219 21.4055 1.54997 20.8833C1.02774 20.361 0.766632 19.7333 0.766632 18.9999V2.99992C0.766632 2.26659 1.02774 1.63881 1.54997 1.11659C2.07219 0.594363 2.69997 0.333252 3.4333 0.333252H24.7666C25.5 0.333252 26.1277 0.594363 26.65 1.11659C27.1722 1.63881 27.4333 2.26659 27.4333 2.99992V18.9999C27.4333 19.7333 27.1722 20.361 26.65 20.8833C26.1277 21.4055 25.5 21.6666 24.7666 21.6666H3.4333ZM3.4333 18.9999H24.7666V2.99992H3.4333V18.9999Z" fill={activeIndex === 3 ? "#0072BA" : "#313131"}/>
        //         </svg>,
        //     description: "Статті"
        // },
        {
            title: "profile",
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                    <path d="M10.7 10.9999C9.23333 10.9999 7.97777 10.4777 6.93333 9.43325C5.88888 8.38881 5.36666 7.13325 5.36666 5.66659C5.36666 4.19992 5.88888 2.94436 6.93333 1.89992C7.97777 0.855474 9.23333 0.333252 10.7 0.333252C12.1667 0.333252 13.4222 0.855474 14.4667 1.89992C15.5111 2.94436 16.0333 4.19992 16.0333 5.66659C16.0333 7.13325 15.5111 8.38881 14.4667 9.43325C13.4222 10.4777 12.1667 10.9999 10.7 10.9999ZM0.0333252 18.9999V17.9333C0.0333252 17.1777 0.22777 16.4833 0.616659 15.8499C1.00555 15.2166 1.52221 14.7333 2.16666 14.3999C3.54444 13.711 4.94444 13.1944 6.36666 12.8499C7.78888 12.5055 9.23333 12.3333 10.7 12.3333C12.1667 12.3333 13.6111 12.5055 15.0333 12.8499C16.4555 13.1944 17.8555 13.711 19.2333 14.3999C19.8778 14.7333 20.3944 15.2166 20.7833 15.8499C21.1722 16.4833 21.3667 17.1777 21.3667 17.9333V18.9999C21.3667 19.7333 21.1055 20.361 20.5833 20.8833C20.0611 21.4055 19.4333 21.6666 18.7 21.6666H2.69999C1.96666 21.6666 1.33888 21.4055 0.816659 20.8833C0.294436 20.361 0.0333252 19.7333 0.0333252 18.9999ZM2.69999 18.9999H18.7V17.9333C18.7 17.6888 18.6389 17.4666 18.5167 17.2666C18.3944 17.0666 18.2333 16.911 18.0333 16.7999C16.8333 16.1999 15.6222 15.7499 14.4 15.4499C13.1778 15.1499 11.9444 14.9999 10.7 14.9999C9.45555 14.9999 8.22221 15.1499 6.99999 15.4499C5.77777 15.7499 4.56666 16.1999 3.36666 16.7999C3.16666 16.911 3.00555 17.0666 2.88333 17.2666C2.7611 17.4666 2.69999 17.6888 2.69999 17.9333V18.9999ZM10.7 8.33325C11.4333 8.33325 12.0611 8.07214 12.5833 7.54992C13.1055 7.0277 13.3667 6.39992 13.3667 5.66659C13.3667 4.93325 13.1055 4.30547 12.5833 3.78325C12.0611 3.26103 11.4333 2.99992 10.7 2.99992C9.96666 2.99992 9.33888 3.26103 8.81666 3.78325C8.29444 4.30547 8.03333 4.93325 8.03333 5.66659C8.03333 6.39992 8.29444 7.0277 8.81666 7.54992C9.33888 8.07214 9.96666 8.33325 10.7 8.33325Z" fill={activeIndex === 4 ? "#0072BA" : "#313131"}/>
                </svg>,
            description: "Профіль"
        }
    ];

    return (
        <footer style={{position: 'fixed', bottom: 0, left: "12%", right: "12%", padding: '0', zIndex: 6}}>
            <div className="container">
                <div style={{
                    backgroundColor: "#F8F9FF", borderRadius: 42, overflow: "hidden", padding: "0 2% 0 2%"
                }} className="row justify-content-between">
                    {sections.map((item, index) => (
                        <div className="col-2 d-flex justify-content-center align-items-center flex-column" style={{
                            margin: '0 1%', height: 68,
                            borderRadius: activeIndex === index ? 24 : 0,
                            backgroundColor: activeIndex === index ? "#EBEFFF" : "#F8F9FF"
                        }} onClick={() => handleItemClick(index, `${item.title}`)} key={index}>
                            <div style={{
                                height: 32, width: 32
                            }} className="d-flex justify-content-center align-items-center">
                                {item.icon}
                            </div>
                            <p style={{
                                fontFamily: "Nunito", fontSize: 12, fontWeight: 600, color: activeIndex === index ? "#0072BA" : "#313131", margin: 0, padding: 0
                            }}>{item.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </footer>
    );
};

