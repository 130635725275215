import './App.css';
import Page from "./pages/Page";
import {DataBaseProvider} from "./context/DataBaseProvider";
import {NavigationProvider} from "./context/NavigationContext";
import useTelegram from "./hooks/useTelegram";
import {useContext, useEffect} from "react";
import "./assets/fonts/fonts.css";
function App() {
    const { tg, ready, isTelegram, initDataUnsafe } = useTelegram();
    ready();
    tg.expand()

    const handleChangeViewport = () => {
        tg.expand();
    }
    useEffect(() => {
        tg.onEvent('viewportChanged', handleChangeViewport);
        return () => {
            tg.offEvent('viewportChanged', handleChangeViewport);
        }
    }, []);

  return (
    <div className="App">
        {/*{isTelegram &&*/}
        <NavigationProvider>
            <DataBaseProvider>
                <Page />
            </DataBaseProvider>
        </NavigationProvider>
        {/*}*/}
    </div>
  );
}

export default App;
