import {useContext, useEffect, useState} from "react";
import {NavigationContext} from "../context/NavigationContext";
import Logo from "../assets/images/logo-light.png";
import "../components/header/Header.css";
export default function Header() {
    const {handleChangeCurrentPage, currentPage, handleChangeIndexAndCurrentPage} = useContext(NavigationContext);
    const handleItemClick = () => {
        if (currentPage === 'notification') {
            handleChangeIndexAndCurrentPage(0, "")
            return;
        }
        handleChangeCurrentPage('notification');
    };

    const [title, setTitle] = useState(currentPage);

    useEffect(() => {
        switch (currentPage) {
            case "":
                setTitle("Статус зубів");
                break;
            case "appointment":
                setTitle("Ваші записи");
                break;
            case "history":
                setTitle("Сервіси та лікарі");
                break;
            case "recommendation":
                setTitle("Статті");
                break;
            case "profile":
                setTitle("Профіль");
                break;
        }
    }, [currentPage]);

    return (
      <header className="d-flex justify-content-between" style={{
          position: 'fixed', top: 0, left: 0, right: 0,
          height: "60px", padding: "10px 20px", zIndex: 4,  overflow: "hidden",
          backgroundColor: "#fff"
          // borderRadius: "0 0 24px 24px"
      }}>
          <p style={{
              fontFamily: "Nunito",
              fontSize: 32,
              fontWeight: 600
          }}>{title}</p>
          <div style={{position: "relative", width: "28px", height: "28px"}} onClick={handleItemClick}>
              <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
                  position: "absolute", top: "45%"
              }}>
                  <path d="M1.83335 20.1666C1.5028 20.1666 1.22571 20.0548 1.0021 19.8312C0.778493 19.6076 0.666687 19.3305 0.666687 18.9999C0.666687 18.6694 0.778493 18.3923 1.0021 18.1687C1.22571 17.9451 1.5028 17.8333 1.83335 17.8333H3.00002V9.66658C3.00002 8.0527 3.48613 6.61867 4.45835 5.3645C5.43058 4.11034 6.69447 3.28881 8.25002 2.89992V2.08325C8.25002 1.59714 8.42016 1.18395 8.76044 0.843669C9.10072 0.503391 9.51391 0.333252 10 0.333252C10.4861 0.333252 10.8993 0.503391 11.2396 0.843669C11.5799 1.18395 11.75 1.59714 11.75 2.08325V2.89992C13.3056 3.28881 14.5695 4.11034 15.5417 5.3645C16.5139 6.61867 17 8.0527 17 9.66658V17.8333H18.1667C18.4972 17.8333 18.7743 17.9451 18.9979 18.1687C19.2215 18.3923 19.3334 18.6694 19.3334 18.9999C19.3334 19.3305 19.2215 19.6076 18.9979 19.8312C18.7743 20.0548 18.4972 20.1666 18.1667 20.1666H1.83335ZM10 23.6666C9.35835 23.6666 8.80905 23.4381 8.3521 22.9812C7.89516 22.5242 7.66669 21.9749 7.66669 21.3333H12.3334C12.3334 21.9749 12.1049 22.5242 11.6479 22.9812C11.191 23.4381 10.6417 23.6666 10 23.6666ZM5.33335 17.8333H14.6667V9.66658C14.6667 8.38325 14.2097 7.28464 13.2959 6.37075C12.382 5.45686 11.2834 4.99992 10 4.99992C8.71669 4.99992 7.61808 5.45686 6.70419 6.37075C5.7903 7.28464 5.33335 8.38325 5.33335 9.66658V17.8333Z" fill="black"/>
              </svg>
              <span style={{
                  position: "absolute", zIndex: 2, borderRadius: "50%",
                  backgroundColor: "#D24343", top: "36%", left: "8px", textAlign: "center", padding: "0 5.5px 0 5.5px",
                  fontFamily: "Nunito", fontSize: 12, fontWeight: 600, color: "#fff"
              }}>3</span>
          </div>
      </header>
    );
}