import {useContext, useEffect, useState} from "react";
import {DataBaseContext} from "../context/DataBaseProvider";
import useTelegram from "../hooks/useTelegram";
import {NavigationContext} from "../context/NavigationContext";

export default function ServiceDetails() {
    const {tg, handleMainButtonText} = useTelegram();
    const {handleChangeCurrentPage, paramId} = useContext(NavigationContext);
    const {ServicesList} = useContext(DataBaseContext);

    const goBack = () => {
        handleChangeCurrentPage("appointment");
    };

    useEffect(() =>{
        tg.BackButton.show();

        tg.onEvent('backButtonClicked', goBack);
        return () => {
            tg.offEvent('backButtonClicked', goBack);
        }
    }, []);

    useEffect(() => {
        handleMainButtonText("Запис");
        tg.MainButton.show();
        tg.MainButton.enable();
    }, []);

    const [service, setService] = useState({});

    useEffect(() => {
        if (paramId) setService(ServicesList.find(item => item.id === paramId));
    }, [ServicesList, paramId]);

    return (
        <div className="container">
            <h1>Service Details:</h1>
            <h5>{service.title}</h5>
            <p>{service.price} ₴</p>
            <p>{service.description}</p>
            {/*<button style={styles[0]}>Запис</button>*/}
        </div>
    )
}