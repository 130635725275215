import {useContext, useEffect, useState} from "react";
import useTelegram from "../hooks/useTelegram";
// import TopJaw from "../assets/images/topJaw.png";
// import BottomJaw from "../assets/images/bottomJaw.png";
import Jaw from "../components/home/Jaw";
import {NavigationContext} from "../context/NavigationContext";
// import Jaw from "../components/home/Jaw";
// import SignListItem from "../components/home/SignListItem";
// import JawTest from "../assets/images/jaw_test.png"
export default function HomePage()
{
    const {tg, onClose} = useTelegram();
    const {handleChangeIndexAndCurrentPage} = useContext(NavigationContext);
    const signListItems = [
        {
            text: "Удаляем",
            color: "#ff0000"
        },
        {
            text: "Отсутсвует",
            color: "#ffffff"
        },
        {
            text: "Нужен ремонт",
            color: "#eeff00"
        },
        {
            text: "Отличное состояние",
            color: "#00ff01"
        }
    ];

    useEffect(() => {
        tg.BackButton.hide();
        tg.MainButton.hide();
    }, []);

    const handleConsultationButton = () => {
        // send command to bot /consultation
        onClose();
    };

    return (
      <div style={{
          height: '91vh', overflow: 'hidden', position: 'relative'
      }}>
          <div style={{
              position: "fixed", top: 60, left: 0, right: 0, backgroundColor: "#fff",
              borderRadius: "0 0 24px 24px", padding: "10px 0 16px 0", zIndex: 3, overflow: "hidden"
          }} className="container d-flex justify-content-evenly">
              <button style={{
                  padding: "12px 21px 12px 21px", backgroundColor: "#0072BA", border: "none", borderRadius: 16,
                  color: "#fff", fontFamily: "Nunito", fontSize: 14, fontWeight: 700
              }} onClick={() => handleChangeIndexAndCurrentPage(2, 'history')} className="d-flex justify-content-center align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0 0 21 19" fill="none">
                      <path d="M3.7 13L6.35 0.75C6.4 0.516667 6.5125 0.333333 6.6875 0.2C6.8625 0.0666667 7.06667 0 7.3 0H7.725C7.95833 0 8.1625 0.0708333 8.3375 0.2125C8.5125 0.354167 8.625 0.541667 8.675 0.775L11.55 14.3L13.325 6.75C13.375 6.53333 13.4875 6.35417 13.6625 6.2125C13.8375 6.07083 14.0417 6 14.275 6H14.75C14.9833 6 15.1833 6.06667 15.35 6.2C15.5167 6.33333 15.625 6.50833 15.675 6.725L17.3 13H19.5C19.7833 13 20.0208 13.0958 20.2125 13.2875C20.4042 13.4792 20.5 13.7167 20.5 14C20.5 14.2833 20.4042 14.5208 20.2125 14.7125C20.0208 14.9042 19.7833 15 19.5 15H16.525C16.2917 15 16.0875 14.9333 15.9125 14.8C15.7375 14.6667 15.6167 14.4833 15.55 14.25L14.55 10.3L12.675 18.25C12.625 18.4833 12.5125 18.6667 12.3375 18.8C12.1625 18.9333 11.9583 19 11.725 19H11.3C11.0667 19 10.8583 18.9292 10.675 18.7875C10.4917 18.6458 10.375 18.4583 10.325 18.225L7.5 4.95L5.475 14.225C5.425 14.4583 5.30833 14.6458 5.125 14.7875C4.94167 14.9292 4.73333 15 4.5 15H1.5C1.21667 15 0.979167 14.9042 0.7875 14.7125C0.595833 14.5208 0.5 14.2833 0.5 14C0.5 13.7167 0.595833 13.4792 0.7875 13.2875C0.979167 13.0958 1.21667 13 1.5 13H3.7Z" fill="white"/>
                  </svg>
                  <div className="d-flex flex-column" style={{
                      marginLeft: 10
                  }}>
                    Пройти огляд
                  </div>

              </button>
              <button style={{
                  padding: "12px 16px 12px 26px", backgroundColor: "#75BFF0", border: "none", borderRadius: 16,
                  color: "#fff", fontFamily: "Nunito", fontSize: 14, fontWeight: 700
              }} onClick={() => handleConsultationButton()} className="d-flex justify-content-center align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="21" viewBox="0 0 18 21" fill="none">
                      <path d="M2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V9C0 7.76667 0.2375 6.60417 0.7125 5.5125C1.1875 4.42083 1.83333 3.46667 2.65 2.65C3.46667 1.83333 4.42083 1.1875 5.5125 0.7125C6.60417 0.2375 7.76667 0 9 0C10.2333 0 11.3958 0.2375 12.4875 0.7125C13.5792 1.1875 14.5333 1.83333 15.35 2.65C16.1667 3.46667 16.8125 4.42083 17.2875 5.5125C17.7625 6.60417 18 7.76667 18 9V19C18 19.55 17.8042 20.0208 17.4125 20.4125C17.0208 20.8042 16.55 21 16 21H10C9.71667 21 9.47917 20.9042 9.2875 20.7125C9.09583 20.5208 9 20.2833 9 20C9 19.7167 9.09583 19.4792 9.2875 19.2875C9.47917 19.0958 9.71667 19 10 19H16V18H14C13.45 18 12.9792 17.8042 12.5875 17.4125C12.1958 17.0208 12 16.55 12 16V12C12 11.45 12.1958 10.9792 12.5875 10.5875C12.9792 10.1958 13.45 10 14 10H16V9C16 7.06667 15.3167 5.41667 13.95 4.05C12.5833 2.68333 10.9333 2 9 2C7.06667 2 5.41667 2.68333 4.05 4.05C2.68333 5.41667 2 7.06667 2 9V10H4C4.55 10 5.02083 10.1958 5.4125 10.5875C5.80417 10.9792 6 11.45 6 12V16C6 16.55 5.80417 17.0208 5.4125 17.4125C5.02083 17.8042 4.55 18 4 18H2ZM2 16H4V12H2V16ZM14 16H16V12H14V16Z" fill="white"/>
                  </svg>
                  <span style={{
                      marginLeft: 10, marginRight: 10
                  }}>Консультація</span>
                  </button>
          </div>

          <span style={{
              backgroundColor: "rgba(0,114,186,0.2)", width: 250, height: 250, position: "absolute", borderRadius: "50%",
              filter: "blur(3rem)", top: 56, left: -106
          }}></span>
          <span style={{
              backgroundColor: "rgba(0,114,186,0.2)", width: 250, height: 250, position: "absolute", borderRadius: "50%",
              filter: "blur(3rem)", top: 137, left: 182
          }}></span>
          <span style={{
              backgroundColor: "rgba(0,114,186,0.2)", width: 250, height: 250, position: "absolute", borderRadius: "50%",
              filter: "blur(3rem)", top: 356, left: -64
          }}></span>
          <span style={{
              backgroundColor: "rgba(0,114,186,0.2)", width: 250, height: 250, position: "absolute", borderRadius: "50%",
              filter: "blur(3rem)", top: 478, left: 180
          }}></span>
          {/*Stars*/}
          <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none" style={{
              position: "absolute", top: "20%", right: "5%"
          }}>
              <g filter="url(#filter0_f_224_1303)">
                  <path d="M15.9657 3.5556C17.3625 15.1485 13.4077 22.5026 3.55082 25.0854C12.2406 24.0198 21.0848 22.6602 25.0674 37.5233C21.6537 25.3635 27.0966 19.0678 37.5186 15.9838C27.3519 18.2806 19.1564 16.2608 15.9657 3.5556Z" fill="white"/>
              </g>
              <defs>
                  <filter id="filter0_f_224_1303" x="0.550812" y="0.555664" width="39.9677" height="39.9678" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                      <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_224_1303"/>
                  </filter>
              </defs>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none" style={{
              position: "absolute", top: "30%", left: "5%"
          }}>
              <g filter="url(#filter0_f_224_1304)">
                  <path d="M15.7477 3.57188C13.1527 9.64698 9.13472 12.1314 3.57466 10.6087C8.13373 12.5447 12.8516 14.3803 10.5984 22.7892C12.3656 15.8658 16.8133 14.3208 22.792 15.7579C17.1611 14.0073 13.717 10.6994 15.7477 3.57188Z" fill="white"/>
              </g>
              <defs>
                  <filter id="filter0_f_224_1304" x="0.574646" y="0.571777" width="25.2173" height="25.2175" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                      <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_224_1304"/>
                  </filter>
              </defs>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none" style={{
              position: "absolute", top: "33%", left: "30%"
          }}>
              <g filter="url(#filter0_f_224_1302)">
                  <path d="M14.9574 3.09735C13.2235 10.0758 9.33737 13.4429 3.0985 12.7719C8.33349 14.0607 13.7217 15.2142 12.7603 24.641C13.4574 16.8948 17.9684 14.4625 24.6422 14.9689C18.2877 14.0653 14.0126 11.1087 14.9574 3.09735Z" fill="white"/>
              </g>
              <defs>
                  <filter id="filter0_f_224_1302" x="0.0985107" y="0.0974121" width="27.5437" height="27.5437" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                      <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_224_1302"/>
                  </filter>
              </defs>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none" style={{
              position: "absolute", bottom: "30%", left: "5%"
          }}>
              <g filter="url(#filter0_f_224_1301)">
                  <path d="M13.2078 3.10009C14.0019 12.0832 10.7689 17.6632 3.09682 19.4155C9.83088 18.8058 16.6913 17.973 19.4014 29.5439C17.0643 20.0737 21.42 15.3472 29.5406 13.2219C21.6364 14.7457 15.3595 12.9858 13.2078 3.10009Z" fill="white"/>
              </g>
              <defs>
                  <filter id="filter0_f_224_1301" x="0.0968018" y="0.100098" width="32.4438" height="32.4438" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                      <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_224_1301"/>
                  </filter>
              </defs>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none" style={{
              position: "absolute", bottom: "12%", left: "10%"
          }}>
              <g filter="url(#filter0_f_224_1300)">
                  <path d="M26.266 3.20007C23.4206 17.4759 15.881 24.6529 3.20136 23.8866C13.9095 25.9972 24.9146 27.8196 23.8633 46.9733C24.5393 31.243 33.431 25.8973 46.9746 26.2893C34.039 25.0639 25.1141 19.49 26.266 3.20007Z" fill="white"/>
              </g>
              <defs>
                  <filter id="filter0_f_224_1300" x="0.201355" y="0.199951" width="49.7732" height="49.7734" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                      <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_224_1300"/>
                  </filter>
              </defs>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none" style={{
              position: "absolute", bottom: "22%", right: "10%"
          }}>
              <g filter="url(#filter0_f_224_1298)">
                  <path d="M23.6193 3.78457C19.6204 13.9863 13.0829 18.3061 3.78865 16.0592C11.444 19.0412 19.3576 21.849 16.0421 35.9029C18.6261 24.336 25.9229 21.5524 35.907 23.6364C26.4838 21.0153 20.6085 15.7031 23.6193 3.78457Z" fill="white"/>
              </g>
              <defs>
                  <filter id="filter0_f_224_1298" x="0.788666" y="0.784668" width="38.1183" height="38.1182" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                      <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_224_1298"/>
                  </filter>
              </defs>
          </svg>
          <Jaw />
          <div style={{
              borderRadius: "50%", width: "46vw", height: "46vw", background: "linear-gradient(#0072BA 0%, #005E99 80%)", position: "absolute",
              top: "50%", left: "50%", transform: "translate(-50%, -50%)", border: "8px solid #fff",
              padding: 10
          }} className="d-flex flex-column justify-content-center align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                  <path d="M14.5309 10.3977C10.3667 10.3977 6.97876 13.7855 6.97876 17.9497C6.97876 18.5851 7.49386 19.1001 8.12915 19.1001C8.76445 19.1001 9.27954 18.5851 9.27954 17.9497C9.27954 15.0541 11.6352 12.6985 14.5309 12.6985C15.1662 12.6985 15.6813 12.1836 15.6813 11.5481C15.6813 10.9126 15.1662 10.3977 14.5309 10.3977Z" fill="white"/>
                  <path d="M35.169 25.3398C34.5337 25.3398 34.0186 25.8548 34.0186 26.4902C34.0186 28.4109 33.229 33.8744 31.4736 40.007C31.2988 40.6176 31.6522 41.2546 32.2629 41.4293C32.8711 41.6042 33.5101 41.2526 33.6854 40.64C35.502 34.2935 36.3191 28.5452 36.3191 26.4903C36.3194 25.8549 35.8043 25.3398 35.169 25.3398Z" fill="white"/>
                  <path d="M37.8811 0.889404C36.8194 8.28166 33.1626 12.1351 26.6747 12.0957C32.2189 12.8822 37.8418 13.5113 37.8811 23.3021C37.7631 15.2414 42.167 12.253 49.0874 12.0957C42.4423 11.8205 37.7238 9.22535 37.8811 0.889404Z" fill="white"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M35.8173 5.54504C32.7602 4.59471 29.3149 4.75942 26.108 6.42198C24.1238 7.45071 21.7545 7.45045 19.7705 6.42198C11.1976 1.97759 0.912598 8.23914 0.912598 17.8996C0.912598 23.9792 5.95279 49.1106 13.8331 49.1106C16.5973 49.1106 19.113 46.2906 21.524 40.4897C22.0453 39.2357 23.8334 39.2353 24.3546 40.4897C26.7656 46.2906 29.2812 49.1106 32.0455 49.1106C39.8486 49.1106 44.966 24.1726 44.966 17.8996C44.966 16.3584 44.7041 14.9036 44.2291 13.5649C43.5832 13.9555 42.8543 14.2346 42.073 14.3732C42.4548 15.4629 42.6651 16.6462 42.6651 17.8998C42.6651 25.008 37.0885 46.81 32.0454 46.81C30.4105 46.81 28.3816 44.1846 26.4791 39.6067C25.1717 36.4614 20.7073 36.4599 19.3994 39.6069C17.497 44.1845 15.4681 46.81 13.8332 46.81C8.79376 46.81 3.21338 25.0387 3.21338 17.8998C3.21338 9.95084 11.6767 4.81788 18.7117 8.46461C21.3589 9.83694 24.5197 9.83694 27.1669 8.46461C29.7192 7.14148 32.4619 6.97446 34.9199 7.67945C35.0665 6.90319 35.3773 6.18065 35.8173 5.54504Z" fill="white"/>
              </svg>
              <span style={{
                  border: "2px solid #fff", padding: "8px 12px 8px 12px", borderRadius: 8, position: "absolute",
                  left: "-32%", top: "43%",  fontFamily: "Nunito", fontWeight: 700, fontSize: 14, textAlign: "center"
              }}>L</span>
              <span style={{
                  border: "2px solid #fff", padding: "8px 12px 8px 12px", borderRadius: 8, position: "absolute",
                  right: "-32%", top: "43%",  fontFamily: "Nunito", fontWeight: 700, fontSize: 14, textAlign: "center"
              }}>R</span>
              <span style={{
                  position: "absolute", width: "20vw", height: "20vw", backgroundColor: "#fff", filter: "blur(2rem)",
                  top: "50%", left: "50%", transform: "translate(-55%, -50%)", zIndex: 1
              }}></span>
              <p style={{
                    margin: 0, padding: 0, color: "#FFFFFF", fontFamily: "Nunito", fontWeight: 700, fontSize: 20, zIndex: 3
              }}>Здорові</p>
              <p style={{
                  margin: 0, padding: 0, color: "#FFFFFF", fontFamily: "Nunito", fontWeight: 700, fontSize: 20, zIndex: 3
              }}>зуби</p>
          </div>
      </div>
    );
}
