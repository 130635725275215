import {useContext, useEffect} from "react";
import {DataBaseContext} from "../context/DataBaseProvider";
import NotificationItem from "../components/notifications/NotificationItem";
import {NavigationContext} from "../context/NavigationContext";
import useTelegram from "../hooks/useTelegram";

export default function Notification() {
    const {notifications} = useContext(DataBaseContext);
    const {handleChangeCurrentPage} = useContext(NavigationContext);
    const {tg} = useTelegram();

    const goBack = () => {
        handleChangeCurrentPage("");
    };

    useEffect(() =>{
        tg.BackButton.show();

        tg.BackButton.onClick(goBack);
        return () => {
            tg.BackButton.offClick(goBack);
        }
    }, []);
    return (
        <>
            <div style={{
                position: "fixed", top: 60, left: 0, right: 0, backgroundColor: "#fff",
                borderRadius: "0 0 24px 24px", padding: "10px 0 16px 0", zIndex: 3, overflow: "hidden"
            }} className="container d-flex justify-content-evenly"></div>
            <div style={{overflowY: 'auto', paddingBottom: "40px", marginTop: "8%"}}>
                <div className="container">
                    {notifications.map((item, index) => (
                        <NotificationItem item={item} key={index} index={index}/>
                    ))}
                </div>
            </div>
        </>

    );
}