import {useContext, useEffect} from "react";
import {NavigationContext} from "../../context/NavigationContext";
import useTelegram from "../../hooks/useTelegram";

export default function Consultation() {
    const {handleChangeCurrentPage} = useContext(NavigationContext);
    const {tg} = useTelegram();

    const goBack = () => {
        handleChangeCurrentPage("profile");
    };

    useEffect(() =>{
        tg.BackButton.show();

        tg.BackButton.onClick(goBack);
        return () => {
            tg.BackButton.offClick(goBack);
        }
    }, []);
    return (
        <main className="container">
            <h1>Consultation</h1>
        </main>
    )
}