import React, {useContext, useEffect, useState} from 'react';
import {NavigationContext} from "../../context/NavigationContext";
import useDate from "../../hooks/useDate";
import {DataBaseContext} from "../../context/DataBaseProvider";

export default function DaysTable({ daysMap, currentDate, month, year, mode, handleMode, scrollToAppointment, handleChangeMonthAndYear }){
    const {weekDays, monthsUkrainian} = useDate();
    const {profileData} = useContext(DataBaseContext);
    const [currentDayIndex, setCurrentDayIndex] = useState({});
    const [isDisplayCurrentDay, setIsDisplayCurrentDay] = useState(true);
    const [isDisplaySelectedDay, setIsDisplaySelectedDay] = useState(false);
    const [keysForCurrentMonth, setKeysForCurrentMonth] = useState([]);
    const [selectedDayChunkIndex, setSelectedDayChunkIndex] = useState(0);
    const [selectedDay, setSelectedDay] = useState([]);
    const chunkedNumbers = [];
    const size = 7;

    // Make chunks for calendar
    for (let i = 0; i < daysMap.length; i += size) {
        chunkedNumbers.push(daysMap.slice(i, i + size));
    }

    const findDayByIndex = (currentDay, chunkIndex) => {
        const index = chunkedNumbers[chunkIndex].findIndex(item => item === currentDay);
        if (index === -1) {
            if (currentDay > chunkedNumbers[chunkIndex][0]) {
                return findDayByIndex(currentDay, chunkIndex + 1);
            }
            return findDayByIndex(currentDay, chunkIndex - 1);
        }
        return {chunkIndex, index};
    };

    useEffect(() => {
        // Find current day index in chunk
        const currentDayIndex = findDayByIndex(currentDate[0], 2);
        setCurrentDayIndex(currentDayIndex);
        setSelectedDayChunkIndex(currentDayIndex.chunkIndex);
    }, []);


    useEffect(() => {
        // If received month and year match current month and year turn on IsDisplayCurrentDay
        if (Number(month) === currentDate[1] && Number(year) === currentDate[2]) {
            setIsDisplayCurrentDay(true);
            return;
        }
        // else turn off
        setIsDisplayCurrentDay(false);
    }, [currentDate, month, year]);

    useEffect(() => {
        if (Number(month) === selectedDay[1] && Number(year) === selectedDay[2]) {
            setIsDisplaySelectedDay(true)
            return;
        }
        setIsDisplaySelectedDay(false);
    }, [selectedDay, month, year]);


    function getKeysForCurrentMonth(appointments) { // Add 1 because getMonth returns zero-based index
        const keysForCurrentMonth = [];

        appointments.map(appointment => {
            const [date, time] = appointment.visit_start.split(" ");
            const dateParts = date.split('-');
            const thisMonth = parseInt(dateParts[1], 10);
            const thisYear = parseInt(dateParts[0], 10);
            if (thisMonth === month && thisYear === year) {
                keysForCurrentMonth.push(parseInt(dateParts[2], 10));
            }
        })

        return keysForCurrentMonth;
    }

    // Find days in month with appointments
    useEffect(() => {
        const keysForCurrentMonth = getKeysForCurrentMonth(profileData.appointments);
        setKeysForCurrentMonth(keysForCurrentMonth);
    }, [profileData.appointments, month]);

    // Process click on day in calendar
    const handleClickDay = (day, isPreviousMonth) => {
        if (isPreviousMonth === 1) {
            if (month === 12) {
                setSelectedDay([day, 1, year + 1]);
                handleChangeMonthAndYear(1, year + 1);
            } else {
                handleChangeMonthAndYear(month + 1, year);
                setSelectedDay([day, month + 1, year]);
            }
        } else if (isPreviousMonth === -1) {
            if (month === 1) {
                handleChangeMonthAndYear(12, year - 1);
                setSelectedDay([day, 12, year - 1]);
            } else {
                handleChangeMonthAndYear(month - 1, year);
                setSelectedDay([day, month - 1, year]);
            }
        } else {
            setSelectedDay([day, month, year]);
        }

        setSelectedDayChunkIndex(findDayByIndex(day, 2)['chunkIndex']);
        // handleSpecialDay(day)
        // setSpecialDayIndex(findDayByIndex(day, 2));
        handleMode(true);
        const date = `${day}-${month.length > 1 ? month : `0${month}`}-${year}`
        scrollToAppointment(date);
        // handleChangeCurrentPage('dayDetails');
        // handleChangeDate([day, month, year]);
    };

    const handleChangeMonthButtonClick = (digit) => {
        if (mode) {
            // Add condition for change month if chunks ended
            if (selectedDayChunkIndex === 0 && digit === -1) {
                const firstDayWeekDayIndexOfPreviousMonth = new Date(year, month - 2, 1).getDay();
                const daysOfPreviousMonth = new Date(year, month - 1, 0).getDate();

                let chunkIndex = 3;
                if (firstDayWeekDayIndexOfPreviousMonth === 6 && daysOfPreviousMonth === 31){
                    chunkIndex = 4;
                } else if (firstDayWeekDayIndexOfPreviousMonth === 7 && daysOfPreviousMonth > 29) {
                    chunkIndex = 4;
                }
                setSelectedDayChunkIndex(chunkIndex);
                handleChangeMonthAndYear((month === 1) ? 12 : month - 1, (month === 1) ? year - 1 : year);
            } else if (selectedDayChunkIndex > 3 && digit === 1) {
                const daysOfPreviousMonth = new Date(year, month - 1, 0).getDate();
                if (chunkedNumbers[selectedDayChunkIndex][6] === daysOfPreviousMonth) {
                    setSelectedDayChunkIndex(1);
                    handleChangeMonthAndYear((month === 12) ? 1 : month + 1, (month === 12) ? year + 1 : year);
                } else if (chunkedNumbers[selectedDayChunkIndex][6] < 8) {
                    setSelectedDayChunkIndex(1);
                    handleChangeMonthAndYear((month === 12) ? 1 : month + 1, (month === 12) ? year + 1 : year);
                } else {
                    setSelectedDayChunkIndex(selectedDayChunkIndex + digit);
                }
            } else {
                setSelectedDayChunkIndex(selectedDayChunkIndex + digit);
            }
        } else {
            if (selectedDay.length === 0) {
                if (currentDate[1] === month && currentDate[2] === year) {
                    console.log(currentDate[1], month, currentDate[2], year)
                    setSelectedDayChunkIndex(currentDayIndex.chunkIndex);
                }
                setSelectedDayChunkIndex(0);
            }
            if (digit === -1) {
                const thisMonth = month === 1 ? 12 : month - 1;
                const thisYear = month === 1 ? year - 1 : year;
                handleChangeMonthAndYear(thisMonth, thisYear)
            } else if (digit === 1) {
                const thisMonth = month === 12 ? 1 : month + 1;
                const thisYear = month === 12 ? year + 1 : year;
                handleChangeMonthAndYear(thisMonth, thisYear)
            }
        }
    }

    // Make calendar ceil
    const CalendarCeil = (subIndex, index, day) => {
        let isActive = false; // Whether the day belongs to the selected month or not
        let isCurrentDay = false; // Day is current day or not
        let isSelectedDay = false; // Day is special day or not
        let isAppointmentDay = false; // Day has appointment or not
        let isPreviousMonth = 0;

        // Condition for check isCurrentDay
        if (isDisplayCurrentDay) {
            if (index === currentDayIndex.chunkIndex && subIndex === currentDayIndex.index) {
                isCurrentDay = true;
            }
        }
        // Condition for check isActive
        if ((index === 0 && day > 20) ) {
            isActive = true;
            isPreviousMonth = -1;
        } else if ((index >= chunkedNumbers.length - 2 && day < 20))  {
            isActive = true;
            isPreviousMonth = 1;
        } else {
            isAppointmentDay = keysForCurrentMonth.includes(day);
        }

        // Condition for check isSelectedDay
        if (isDisplaySelectedDay) {
            if (!isActive && selectedDay[0] === day){
                isSelectedDay = true;
            }
        }

        return (
            <td key={subIndex} style={{
                width: 'calc(100% / 7)',
                padding: isSelectedDay || isCurrentDay ? 4 : 16,
                color: "#000000",
                fontFamily: "Nunito",
                fontSize: 16,
                fontWeight: 600,
                position: "relative"
            }} onClick={() => handleClickDay(day, isPreviousMonth)}>
                {
                    isSelectedDay && isCurrentDay
                        ?
                    (
                        <div style={{
                            paddingTop: 9,
                            paddingBottom: 9,
                            borderRadius: 16,
                            backgroundColor: "#fff",
                            color: "#fff",
                            border: "3px solid #0072BA"
                        }}>
                            <span style={{
                                backgroundColor: "#0072BA", padding: "8px 8px 9px 8px", borderRadius: 12
                            }}>
                                {day}
                            </span>
                        </div>
                    )
                        :
                    isSelectedDay
                        ?
                    (
                        <div style={{
                            paddingTop: 12,
                            paddingBottom: 12,
                            borderRadius: 16,
                            backgroundColor: "#0072BA",
                            color: "#fff"
                        }}>
                            {day}
                        </div>
                    )
                        :
                    isCurrentDay
                        ?
                    (
                        <div style={{
                            paddingTop: 9,
                            paddingBottom: 9,
                            borderRadius: 16,
                            backgroundColor: "#fff",
                            color: "#000",
                            border: "3px solid #0072BA"
                        }}>
                            {day}
                        </div>
                    )
                        :
                    (
                        <span style={{ color: isActive ? "#4E4E4E" : undefined}}>
                            {day}
                        </span>
                    )
                }
                {isAppointmentDay && (
                    <span style={{
                        background: "#7FC8F8",
                        width: 6,
                        height: 6,
                        borderRadius: "50%",
                        position: "absolute",
                        left: "45%",
                        top: "calc(50% + 10px)"
                    }}></span>
                )}
            </td>
        );
    };

    return (
        <>
        <table style={{
            tableLayout: "fixed",
            width: "96%",
            marginLeft: "2%", marginRight: "2%",
            backgroundColor: "#fff"
        }}>
            <thead style={{
                textAlign: "center"

            }}>
            <tr>
                {weekDays.map((item, index) => (
                    <th key={index} style={{
                        width: 'calc(100% / 7)',
                        color: "#383838",
                        fontFamily: "Nunito",
                        fontSize: 14,
                        fontWeight: "normal"
                    }}>{item}</th>
                ))}
            </tr>
            </thead>
            <tbody style={{
                textAlign: "center",
            }}>
            {/*// ONE ROW CALENDAR MODE*/}
            {mode ?
                <tr>
                    {chunkedNumbers[selectedDayChunkIndex].map((day, subIndex) => {
                        return CalendarCeil(subIndex, selectedDayChunkIndex, day);
                    })}
                </tr>
                :
                // FULL CALENDAR MODE
                chunkedNumbers.map((chunk, index) => (
                    <tr key={index}>
                    {chunk.map((day, subIndex) => {
                        return CalendarCeil(subIndex, index, day);
                    })}
                    </tr>
                ))
            }
            {/*{mode && Object.keys(specialDayIndex).length > 0 ? */}
            {/*    <tr>*/}
            {/*        {chunkedNumbers[specialDayIndex.chunkIndex].map((day, subIndex) => {*/}
            {/*            return CalendarCeil(subIndex, specialDayIndex.chunkIndex, day);*/}
            {/*        })}*/}
            {/*    </tr>*/}
            {/*: // FULL CALENDAR MODE*/}
            {/*{chunkedNumbers.map((chunk, index) => (*/}
            {/*        <tr key={index}>*/}
            {/*        {chunk.map((day, subIndex) => {*/}
            {/*            return CalendarCeil(subIndex, index, day);*/}
            {/*        })}*/}
            {/*        </tr>*/}
            {/*    ))*/}
            {/*}*/}
            </tbody>
        </table>
        <div className="d-flex justify-content-between container" style={{
            fontFamily: "Nunito", fontWeight: 600, fontSize: 16, textAlign: "center"
        }}>
            <div style={{
                height: 24, width: 24
            }} onClick={() => handleChangeMonthButtonClick(-1)}>
                <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.08172 6.57495L7.27903 10.7723C7.47634 10.9696 7.575 11.2207 7.575 11.5256C7.575 11.8306 7.47634 12.0817 7.27903 12.279C7.08172 12.4763 6.8306 12.575 6.52567 12.575C6.22074 12.575 5.96962 12.4763 5.77231 12.279L0.821633 7.32831C0.71401 7.22069 0.637777 7.1041 0.592934 6.97854C0.548091 6.85298 0.525669 6.71845 0.525669 6.57495C0.525669 6.43145 0.548091 6.29692 0.592934 6.17136C0.637777 6.0458 0.71401 5.92921 0.821633 5.82159L5.77231 0.870915C5.96962 0.673606 6.22074 0.574951 6.52567 0.574951C6.8306 0.574951 7.08172 0.673606 7.27903 0.870915C7.47634 1.06822 7.575 1.31935 7.575 1.62428C7.575 1.92921 7.47634 2.18033 7.27903 2.37764L3.08172 6.57495Z" fill="black"/>
                </svg>
            </div>
            <div>
                {`${monthsUkrainian[month - 1]} ${year}`}
                <span style={{
                    width: 24, height: 24, marginLeft: 8
                }} onClick={() => handleMode(!mode)}>
                                {mode ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
                                        <path d="M6.575 4.91832L10.7723 0.721013C10.9696 0.523704 11.2207 0.425049 11.5257 0.425049C11.8306 0.425049 12.0817 0.523704 12.279 0.721013C12.4763 0.918322 12.575 1.16944 12.575 1.47438C12.575 1.77931 12.4763 2.03043 12.279 2.22774L7.32836 7.17841C7.22074 7.28604 7.10415 7.36227 6.97859 7.40711C6.85303 7.45195 6.7185 7.47438 6.575 7.47438C6.4315 7.47438 6.29697 7.45195 6.17141 7.40711C6.04585 7.36227 5.92926 7.28604 5.82164 7.17841L0.870965 2.22774C0.673656 2.03043 0.575001 1.77931 0.575001 1.47438C0.575001 1.16944 0.673656 0.918322 0.870965 0.721013C1.06827 0.523704 1.3194 0.425049 1.62433 0.425049C1.92926 0.425049 2.18038 0.523704 2.37769 0.721013L6.575 4.91832Z" fill="black"/>
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
                                        <path d="M6.57501 3.08168L10.7723 7.27899C10.9696 7.4763 11.2208 7.57495 11.5257 7.57495C11.8306 7.57495 12.0817 7.4763 12.279 7.27899C12.4764 7.08168 12.575 6.83056 12.575 6.52562C12.575 6.22069 12.4764 5.96957 12.279 5.77226L7.32838 0.821588C7.22075 0.713964 7.10416 0.637731 6.9786 0.592888C6.85304 0.548045 6.71851 0.525623 6.57501 0.525623C6.43151 0.525623 6.29699 0.548045 6.17142 0.592888C6.04586 0.637731 5.92927 0.713964 5.82165 0.821588L0.870976 5.77226C0.673667 5.96957 0.575012 6.22069 0.575012 6.52562C0.575012 6.83056 0.673667 7.08168 0.870976 7.27899C1.06829 7.4763 1.31941 7.57495 1.62434 7.57495C1.92927 7.57495 2.18039 7.4763 2.3777 7.27899L6.57501 3.08168Z" fill="black"/>
                                    </svg>
                                }
                            </span>
            </div>
            <div style={{
                height: 24, width: 24
            }} onClick={() => handleChangeMonthButtonClick(1)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none">
                    <path d="M4.91826 6.57495L0.720952 10.7723C0.523643 10.9696 0.424988 11.2207 0.424988 11.5256C0.424988 11.8306 0.523643 12.0817 0.720952 12.279C0.918261 12.4763 1.16938 12.575 1.47432 12.575C1.77925 12.575 2.03037 12.4763 2.22768 12.279L7.17835 7.32831C7.28597 7.22069 7.36221 7.1041 7.40705 6.97854C7.45189 6.85298 7.47432 6.71845 7.47432 6.57495C7.47432 6.43145 7.45189 6.29692 7.40705 6.17136C7.36221 6.0458 7.28597 5.92921 7.17835 5.82159L2.22768 0.870915C2.03037 0.673606 1.77925 0.574951 1.47432 0.574951C1.16938 0.574951 0.918261 0.673606 0.720952 0.870915C0.523643 1.06822 0.424988 1.31935 0.424988 1.62428C0.424988 1.92921 0.523643 2.18033 0.720952 2.37764L4.91826 6.57495Z" fill="black"/>
                </svg>
            </div>
        </div>
        </>
    );
};