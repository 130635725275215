import useTelegram from "../hooks/useTelegram";
import React, {useEffect, useState} from "react";
import PopularArticleItem from "../components/recommendation/PopularArticleItem";
import FutureAppointmentItem from "../components/appointment/FutureAppointmentItem";
import ArticleItem from "../components/recommendation/ArticleItem";


export default function Recommendation() {
    const {tg} = useTelegram();

    useEffect(() => {
        tg.BackButton.hide();
        tg.MainButton.hide();
    }, []);

    const categories = [
        "Збережені", "Гігієна", "Засоби догляду", "Планове обстеження"
    ];

    const sections = [
        {
            id: 0,
            category: [
                "Гігієна", "Засоби догляду"
            ],
            title: "Звички для здорових зубів",
            description: "Підходи, які забезпечать вашій ротовій порожнині оптимальний догляд",
            image: "https://s3-alpha-sig.figma.com/img/9b7e/c527/825767709768ee7cf5ceb90b715300eb?Expires=1715558400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=WItnk5AVQJamCFxoytjxtmsyXr7KDReZxRccvcIHhoKz2i8wcXOrxa7tywi2KkooL9PFvtc~9RGd-PGUWeKeuBCEa2AG5P3LiWF~KIhxKCh9-QlCympWevg4YHyPXhiHsmol7AkpPunXei1woyi-HVsSjRP1Aq0-G9E48fNI8bgtXJ~1HOIpUI1egs0BqSlRlkJaUYEgw8IpANePk-ULvNgxNKOgN2DwqpyMwTKqy0enTntf2FxlcIQ1Qg3hzcBZeVhT-ufvdrXT~il64y9StT2D8e16LhVlDtbGWnZOIEnUcZwUFs0s9elhbPIVIenGoGT5ajkXFj6vRLU5SaatmQ__",
            popular: 0
        },
        {
            id: 1,
            category: [
                "Гігієна", "Засоби догляду"
            ],
            title: "Більше, ніж просто чистка",
            description: "Ритуали ранкової та вечірньої гігієни ротової порожнини для здорових зубів",
            image: "https://s3-alpha-sig.figma.com/img/00bb/1f1c/d0d9b15f5b5caeb6cde7b59190b9ec68?Expires=1715558400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=p2Mb7mWeqabAmkoBWApSr8x88O7~MfrHR7ALDzQ02hT~mNgR1PhEwQyUleZKRWc8lPHKK2ZQ1Bukqq8bVzaAz21LtVPRai6rI5YpOJhhoZ3A31YL~nMn69K2K0MC-6uioKD2NWehg4TMPMtfXzHOwxOqmOTgqny1DWZsu3nos5~BFq8B5y6MRlXko5vJhdcbexvJ3~e4Ch3GKZ4iUM60tZRaS6-7p0nfpg4DN-cuV32v6aGa3VWyu6lHCasVZR9Ifrzu5nuTLAwTGXnjPwKmYZ0vfewXOWw8eALHnpapJD1N7dAoodSzLuhtDOyyBdoSCaLlpIxxteDQdLGdzT3rqw__",
            popular: 0
        },
        {
            id: 2,
            category: [
                "Гігієна", "Засоби догляду"
            ],
            title: "Мудрість утримання зубів",
            description: "Як впоратися з видаленням зубів мудрості без стресу",
            image: "https://s3-alpha-sig.figma.com/img/7cfb/1d66/fc61ace570f5342dc84a9c686cb9862f?Expires=1715558400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=OaKLUCghRju9DGI1tp7FJMjI9s~4vTS-B1N1-ZzqbgSqlqYgjDw5xp5o8mrxxgluPDqhaNqH~r8ka2FqbSObFAHP0LHDKfbDwLL2iD11YrCg8kquOdM99ffBuYS7NRSI8VleoldJxDnDIf9ThAbRvc8TR6bHCMgxvURsWk1dQ7czPkueTdtq0vZj9UZKc~7IsEMoIWeuR94edVRMqjtBYOAr2KvXgJ~yaqTMI1vrjw81356I3P-khXGZoIH~mQmX7SIV4SUCnH9a8oRYCmFsQEajPkI4LOqME6~K0IzvZAslAUzFJYdr-l~5UcOxMzKEDw-zXfv6gT-qfDR8fEaOBQ__",
            popular: 0
        },
        {
            id: 3,
            category: [
                "Гігієна", "Засоби догляду"
            ],
            title: "Найкращі продукти для здорових зубів",
            description: "Найефективніші засобив для гігієни ротової порожнини",
            image: "https://s3-alpha-sig.figma.com/img/0883/c24e/cd8c9aac5fffda9456b828a7fc33e704?Expires=1715558400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=c6noquwzZHXKc5pns5BRlQpKSr4Kf8G1IvensQextUMgJXVEoi93Dyj-EVEXgq9hnaC076bzOgQas5sTGvTsR-l8CDb9AVf-SdABDb1yVbeaSh3mq8p1UzkKvvhcTiw9HoIs-lF-SLe6PMz~dO3YUFMD9O1ngv1LEJ8JWOLJEUAeuwCy0MX2rAz80yvB19aJ5arYlTO87-tCkpK2V~y8otgfTFTyhxmZmWZ92bijXnu62g9S3wXG~rMM1IdNlauYOYbf3-8zzX2WXbcNva17O2wqiaPxxUnQpzkrjlNE072CKYzqL8Zu0VYdRxu1eynq3WTzIi5btfq50mNfkhYokg__",
            popular: 1
        },
        {
            id: 4,
            category: [
                "Гігієна", "Засоби догляду"
            ],
            title: "Зубна нитка та більше",
            description: "Забуті аспекти гігієни ротової порожнини, які потрібно врахувати Забуті аспекти гігієни ротової порожнини, які потрібно врахувати Забуті аспекти гігієни ротової порожнини, які потрібно врахувати",
            image: "https://s3-alpha-sig.figma.com/img/d9c7/2416/867929ee0b9319d31fc0211b9e62d081?Expires=1715558400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=VJ1LkN~dIpejB4njtyf3jvGERnZYOm9rPZcuooDtqyUQGWXzZQtCOM-F1M~~VujZHdj7PYvv6KR9upvzvtrrXvjY3~HiHNMuhL-q6SyBFm4ikFXnabVedvAk2mUsDwiE-vL43v8MVVbMgM7OZ2JDuy1msLmy6Xi7HZOV7ZQtoGT3bYznkxqXzIkIGf7PO9eCeN-wY1IYl5adRkwB4CGiwXTrkSmLRPDjJlKpcO8yBVXRZFaewnQGvV~NtX15ML3fN55OSYe0a3~NQpoY-iR2CFZ23enPPvJqDnxJmW-dtOPY31t0pf~dXcd0gz~BfCQxyLdvL1Uh4VIlbdytu7LGDg__",
            popular: 1
        }
    ];

    const [selectedCategory, setSelectedCategory] = useState("");

    const handleSelectCategory = (category) => {
        setSelectedCategory(category);
    };

    return (
        <div>
            <div style={{
                position: "fixed", top: 60, left: 0, right: 0, backgroundColor: "#fff",
                borderRadius: "0 0 24px 24px", padding: "10px 0 16px 0", zIndex: 3, overflow: "hidden"
            }} className="container d-flex justify-content-evenly">
                <div style={{
                    overflowX: "auto"
                }} className="container d-flex justify-content-between no-scrollbar">
                    <div style={{
                        border: "1px solid #E0E5F9", backgroundColor: selectedCategory === "" ? "#0072BA" : "#fff",
                        padding: "8px 12px 8px 12px", borderRadius: 12, textAlign: "center", marginRight: 12,
                        fontFamily: "Nunito", fontSize: 12, fontWeight: "normal", whiteSpace: "nowrap",
                        color: selectedCategory === "" ? "#fff" : "#000"
                    }} onClick={() => handleSelectCategory("")}>
                        Всі
                    </div>
                    {categories.map((item, index) => (
                        <div key={index} style={{
                            border: "1px solid #E0E5F9", backgroundColor: selectedCategory === item ? "#0072BA" : "#fff",
                            padding: "8px 12px 8px 12px", borderRadius: 12, textAlign: "center", marginRight: 12,
                            fontFamily: "Nunito", fontSize: 12, fontWeight: "normal", whiteSpace: "nowrap",
                            color: selectedCategory === item ? "#fff" : "#000"
                        }} onClick={() => handleSelectCategory(item)}>
                            {item}
                        </div>
                    ))}
                </div>
            </div>
            <div style={{
                overflowY: "auto", marginTop: 60, paddingBottom: 80
            }} className="container">
                <h2 style={{
                    marginTop: 20, fontFamily: "Nunito", fontWeight: 600, fontSize: 24, marginBottom: 20
                }} className="container">Популярні</h2>
                <div className="d-flex no-scrollbar" style={{
                    overflowX: "auto"
                }}>
                    {sections.filter(section => section.popular === 1).map((item, index) => (
                        <PopularArticleItem item={item} key={index} />
                        // <FutureAppointmentItem index={index} subIndex={index} time={index} service_id={index} doctor_id={index} date={"22-06-2024"} />
                    ))}
                </div>
                <h2 style={{
                    marginTop: 20, fontFamily: "Nunito", fontWeight: 600, fontSize: 24, marginBottom: 20
                }} className="container">Для вас</h2>
                {sections.filter(section => section.popular === 0).map((item, index) => (
                    <ArticleItem article={item} key={index} />
                ))}
            </div>
        </div>
    );
}