import {useContext, useEffect, useState} from "react";
import {NavigationContext} from "../context/NavigationContext";
import {DataBaseContext} from "../context/DataBaseProvider";
import useTelegram from "../hooks/useTelegram";
import useDate from "../hooks/useDate";

export default function DayDetails() {
    const {date, handleChangeCurrentPage} = useContext(NavigationContext);
    const {tg} = useTelegram();
    const {monthsUkrainian} = useDate();
    const {profileData} = useContext(DataBaseContext);
    const [appointments, setAppointments] = useState([]);

    const goBack = () => {
        handleChangeCurrentPage("appointment");
    };

    useEffect(() =>{
        tg.BackButton.show();

        tg.BackButton.onClick(goBack);
        return () => {
            tg.BackButton.offClick(goBack);
        }
    }, []);

    const findAppointmentsByDate = (date) => {
        if (profileData.appointments[date]) {
            return profileData.appointments[date];
        } else {
            return [];
        }
    };
    useEffect(() => {
        const dateToString = `${date[0]}-${date[1].toString().length === 1 ? `0${date[1]}` : `${date[1]}`}-${date[2]}`
        setAppointments(findAppointmentsByDate(dateToString));
    }, [profileData]);
    return (
        <div>
            <p>{date[0] + " " + monthsUkrainian[date[1] - 1] + " " + date[2]}</p>
            {appointments.length > 1 ?
                appointments.map((item, index) => (
                    <div>
                        <div>{item.service_id}</div>
                        <div>{item.doctor_id}</div>
                        <div>{item.time}</div>
                    </div>
                )) :
                <p>You don't have an appointment</p>
            }
        </div>
    );
}