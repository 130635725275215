import React, {useEffect, useState} from "react";
import DaysTable from "./DaysTable";
import useDate from "../../hooks/useDate";
export default function Calendar({mode, handleMode, scrollToAppointment}) {
    const {getCurrentDate} = useDate();
    const currentDate = getCurrentDate();
    const [month, setMonth] = useState(currentDate[1]);
    const [year, setYear] = useState(currentDate[2]);

    const [weekDays, setWeekDays] = useState(getDaysInMonthWithWeekdays(month, year));

    const handleChangeMonthAndYear = (receivedMonth, receivedYear) => {
        setMonth(receivedMonth);
        setYear(receivedYear);
    }

    function getDaysInMonthWithWeekdays(month, year) {
        const daysInMonth = new Date(year, month, 0).getDate();
        const daysMap = {};

        for (let day = 1; day <= daysInMonth; day++) {
            const date = new Date(year, month - 1, day);
            daysMap[day] = date.toLocaleString('en', {weekday: 'short'});
        }
        return daysMap;
    }

    function generateRange(start, end) {
        const result = [];

        for (let i = start; i <= end; i++) {
            result.push(i);
        }

        return result;
    }

    const missingDays = {
        previousMonth: {
            'Mon': -1,
            'Tue': 0,
            'Wed': 1,
            'Thu': 2,
            'Fri': 3,
            'Sat': 4,
            'Sun': 5
        },
        nextMonth: {
            'Mon': 6,
            'Tue': 5,
            'Wed': 4,
            'Thu': 3,
            'Fri': 2,
            'Sat': 1,
            'Sun': 0
        }
    };

    const getMissingDays = (type, day) => {
        const daysMapping = missingDays[type];
        return daysMapping[day] !== undefined ? daysMapping[day] : -1;
    };

    const getDaysInMonth = () => {
        const dayNumbers = Object.keys(weekDays).map(Number);
        const lastDayOfMonth = Math.max(...dayNumbers);

        const firstDayOfMonthWeekday = weekDays[1];
        const lastDayOfMonthWeekday = weekDays[lastDayOfMonth];

        const previousMonth = getDaysInMonthWithWeekdays(month - 1, year);
        const previousMonthDayNumbers = Object.keys(previousMonth).map(Number);
        const lastDayOfPreviousMonth = Math.max(...previousMonthDayNumbers);


        const mondayDayOfPreviousMonth = getMissingDays('previousMonth', firstDayOfMonthWeekday) === 0 ?
            lastDayOfPreviousMonth :
            lastDayOfPreviousMonth - getMissingDays('previousMonth', firstDayOfMonthWeekday);

        const arrayMonthCalendar = [];

        generateRange(mondayDayOfPreviousMonth, lastDayOfPreviousMonth).forEach(item => {
            arrayMonthCalendar.push(item);
        });

        dayNumbers.forEach(item => {
            arrayMonthCalendar.push(item);
        });

        generateRange(1, getMissingDays('nextMonth', lastDayOfMonthWeekday)).forEach(item => {
            arrayMonthCalendar.push(item);
        });

        const dayInCalendar = 42;

        if (arrayMonthCalendar[arrayMonthCalendar.length - 1] > 20) {
            generateRange(1, dayInCalendar - arrayMonthCalendar.length).forEach(item => {
                arrayMonthCalendar.push(item);
            })
        } else {
            generateRange(arrayMonthCalendar[arrayMonthCalendar.length - 1] + 1,
                arrayMonthCalendar[arrayMonthCalendar.length - 1] + dayInCalendar - arrayMonthCalendar.length)
                .forEach(item => {
                arrayMonthCalendar.push(item);
            })
        }

        return arrayMonthCalendar;
    };

    useEffect(() => {
        setWeekDays(getDaysInMonthWithWeekdays(month, year));
    }, [month, year]);

    return (
        <>
            <DaysTable daysMap={getDaysInMonth()} currentDate={currentDate} month={month} year={year} mode={mode} handleMode={handleMode} handleChangeMonthAndYear={handleChangeMonthAndYear} scrollToAppointment={scrollToAppointment} />
        </>
    );
}