import Header from "./Header";
import {useContext, useEffect, useRef, useState} from "react";
import HomePage from "./HomePage";
import Appointment from "./Appointment";
import History from "./History";
import Profile from "./Profile";
import Recommendation from "./Recommendation";
import ServiceDetails from "./ServiceDetails";
import DoctorDetails from "./DoctorDetails";
import EditForm from "../components/profile/EditForm";
import Consultation from "../components/profile/Consultation";
import Bills from "../components/profile/Bills";
import Settings from "../components/profile/Settings";
import Contacts from "../components/profile/Contacts";
import Propositions from "../components/profile/Propositions";
import Notification from "./Notification";
import {NavigationContext} from "../context/NavigationContext";
import Footer from "../components/footer/Footer";
import DayDetails from "./DayDetails";
import {DataBaseContext} from "../context/DataBaseProvider";
import RegistrationPage from "./RegistrationPage";

export default function Page() {
    const {currentPage, handleChangeIndexAndCurrentPage, activeIndex} = useContext(NavigationContext);
    const { status } = useContext(DataBaseContext);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const pagesWithNavigation = [
        "",
        "appointment",
        "history",
        "recommendation",
        "profile"
    ];

    const pageComponents = {
        '': HomePage,
        'appointment': Appointment,
        'history': History,
        'profile': Profile,
        'recommendation': Recommendation,
        'edit_form': EditForm,
        'consultation': Consultation,
        'bills': Bills,
        'settings': Settings,
        'contacts': Contacts,
        'propositions': Propositions,
        'notification': Notification,
        'serviceDetails': ServiceDetails,
        'doctorDetails': DoctorDetails,
        'dayDetails': DayDetails
    };

    const CurrentPageComponent = pageComponents[currentPage] || null;

    // const containerRef = useRef(null);
    // let isMove = false;
    // let touchStartX = 0;
    // let touchEndX = 0;
    //
    // const handleTouchStart = (event) => {
    //     touchStartX = event.touches[0].clientX;
    // };
    //
    // const handleTouchMove = (event) => {
    //     isMove = true;
    //     touchEndX = event.touches[0].clientX;
    // };
    //
    // const handleTouchEnd = () => {
    //     if (!isMove){
    //         return;
    //     }
    //     if (pagesWithNavigation.includes(currentPage)){
    //         const index = pagesWithNavigation.findIndex((str) => str === currentPage);
    //         if (touchStartX - touchEndX > 100) {
    //             if (index < pagesWithNavigation.length - 1) {
    //                 handleChangeIndexAndCurrentPage(index + 1, pagesWithNavigation[index + 1]);
    //             }
    //         } else if (touchEndX - touchStartX > 100) {
    //             if (index > 0) {
    //                 handleChangeIndexAndCurrentPage(index - 1, pagesWithNavigation[index - 1]);
    //             }
    //         }
    //     }
    //     isMove = false;
    // };

    // return status ? (
    return (
        <>
        <Header />
        <main
            // ref={containerRef}
            // onTouchStart={handleTouchStart}
            // onTouchMove={handleTouchMove}
            // onTouchEnd={handleTouchEnd}
            style={{
                flex: 1, overflowX: 'hidden', marginTop: "60px", marginBottom: `calc(${windowWidth / 5.5 * 1.5})`,
                // minHeight: "90vh"
        }}>
            {CurrentPageComponent && <CurrentPageComponent windowWidth={windowWidth} />}
        </main>
        {(currentPage === '' || pagesWithNavigation.includes(currentPage)) && <Footer activeIndex={activeIndex} handleItemClick={handleChangeIndexAndCurrentPage}  />}
    </>
    // ) : (
    //     <RegistrationPage />
    );
}