import {createContext, useState} from "react";

const NavigationContext = createContext(null);

const NavigationProvider = (props) => {
    const [currentPage, setCurrentPage] = useState("");

    const handleChangeCurrentPage = (page) => {
        setCurrentPage(page);
    }

    const [activeIndex, setActiveIndex] = useState(0);

    const handleChangeIndexAndCurrentPage = (index, title) => {
        setActiveIndex(index);
        handleChangeCurrentPage(title);
    };

    const [paramId, setParamId] = useState("");

    const handleParamId = (id) => {
        setParamId(id);
    }

    const [notificationId, setNotificationId] = useState("");

    const handleNotificationId = (id) => {
        setNotificationId(id);
    }

    const [date, setDate] = useState("");

    const handleChangeDate = (date) => {
        setDate(date);
    }

    return <NavigationContext.Provider value={{
        currentPage, handleChangeCurrentPage, paramId, handleParamId, date, handleChangeDate, notificationId,
        handleNotificationId, activeIndex, handleChangeIndexAndCurrentPage
    }}>
        {props.children}
    </NavigationContext.Provider>
}

export {NavigationProvider, NavigationContext};