import Calendar from "../components/appointment/Calendar";
import useTelegram from "../hooks/useTelegram";
import React, {useContext, useEffect, useRef, useState} from "react";
import {DataBaseContext} from "../context/DataBaseProvider";
import FutureAppointmentItem from "../components/appointment/FutureAppointmentItem";
import HistoryAppointmentItem from "../components/appointment/HistoryAppointmentItem";

export default function Appointment() {
    const {tg} = useTelegram();
    const {profileData} = useContext(DataBaseContext);

    const [mode, setMode] = useState(true);
    const [appointments, setAppointments] = useState([]);
    const [futureAppointments, setFutureAppointments] = useState([]);
    const [historyAppointments, setHistoryAppointments] = useState([]);

    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1
    const day = String(now.getDate()).padStart(2, '0');

    const nowDate = `${year}-${month}-${day}`;

    useEffect(() => {
        tg.BackButton.hide();
        tg.MainButton.hide();
    }, []);

    const handleMode = (value) => {
        setMode(value);
    };

    const scrollContainerRef = useRef(null);
    const historyContainerRef = useRef(null);

    const scrollToAppointment = (date) => {
        // const appointments = profileData.appointments;
        //
        // // Find the first appointment with the specified date in future appointments
        // const futureAppointment = Object.entries(appointments).find(([key]) => key === date);
        //
        // try {
        //     const className = `item-${"0" + futureAppointment[0]}`;
        //     const selectedDiv = scrollContainerRef.current.querySelector(`.${className}`);
        //     if (selectedDiv) {
        //         selectedDiv.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
        //     }
        // } catch (e) {
        //
        // }
    };

    useEffect(() => {
        const now = new Date();
        const future = [];
        const history = [];
        if (profileData.appointments) {
            profileData.appointments.forEach((appointment) => {
                const visitStart = appointment.visit_start;
                const [date, time] = visitStart.split(" ");

                const appointmentDateTime = new Date(`${date}T${time}`);

                let statusUkrainian;
                if (appointment.status === "VISITED") {
                    statusUkrainian = "ВІДВІДАВ"
                } else if (appointment.status === "PLANNED") {
                    statusUkrainian = "ЗАПЛАНОВАНО"
                } else if (appointment.status === "CONFIRMED") {
                    statusUkrainian = "ПІДТВЕРДЖЕНО"
                }

                const appointmentData = {
                    date: date,
                    time: time.slice(0, -3),
                    doctor_id: appointment.doctorId,
                    status: statusUkrainian
                };

                if (appointmentDateTime > now) {
                    future.push(appointmentData);
                } else {
                    history.push(appointmentData);
                }
            });
        }

        // Sort appointments by date
        future.sort((a, b) => new Date(`${a.date}T${a.time}`) - new Date(`${b.date}T${b.time}`));
        history.sort((a, b) => new Date(`${a.date}T${a.time}`) - new Date(`${b.date}T${b.time}`));

        setFutureAppointments(future);
        setHistoryAppointments(history);

    }, []);

    return (
        <div>
            <div style={{
                position: "fixed", top: 60, left: 0, right: 0, backgroundColor: "#fff", borderRadius: "0 0 24px 24px", padding: "0 0 16px 0", zIndex: 3
            }}>
                <Calendar mode={mode} handleMode={handleMode} scrollToAppointment={scrollToAppointment}/>
            </div>
            <div style={{
                marginTop: mode ? 140 : 300
            }}>
                <h2 style={{
                    marginTop: 20, fontFamily: "Nunito", fontWeight: 600, fontSize: 24, marginBottom: 20
                }} className="container">Майбутні Записи</h2>
                <div className="d-flex no-scrollbar" style={{
                    overflowX: "auto"
                }} ref={scrollContainerRef}>
                    {futureAppointments.length !== 0 ?
                        futureAppointments.map((appointment, index) => {
                        return <FutureAppointmentItem
                            date={appointment.date}
                            time = {appointment.time}
                            doctor_id = {appointment.doctor_id}
                            subIndex = {appointment.date + appointment.time}
                            status = {appointment.status}
                                />
                    })
                    :
                        <div style={{
                            margin: "0 16px 0 16px", position: "relative"
                        }}>
                            <div style={{
                                height: 108, borderRadius: 16, padding: 12, backgroundColor: "#fff", marginBottom: 16, position: "relative",
                                borderTop: "6px solid", borderTopColor: "#0072BA", textAlign: "center",
                                display: "flex", alignItems: "center", justifyContent: "center"
                            }}>
                                <p style={{
                                    fontFamily: "Nunito", fontSize: 15, fontWeight: "normal", color: "#000",
                                    whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "100%",
                                    margin: 0, padding: 0
                                }}>На жаль, у вас немає запланнованих записів</p>
                            </div>
                        </div>
                    }
                </div>

                <h2 style={{
                    marginTop: 20, fontFamily: "Nunito", fontWeight: 600, fontSize: 24, marginBottom: 20
                }} className="container">Історія записів</h2>
                <div className="container" ref={historyContainerRef}>
                    {historyAppointments.length !== 0 ?
                        historyAppointments.map((appointment, index) => {
                            return <HistoryAppointmentItem
                                date={appointment.date}
                                time = {appointment.time}
                                doctor_id = {appointment.doctor_id}
                                subIndex = {appointment.date + appointment.time}
                                status = {appointment.status}
                            />
                        }) :
                        <div style={{
                            height: 108, borderRadius: 16, padding: 12, backgroundColor: "#fff", marginBottom: 16, position: "relative",
                            borderTop: "6px solid", borderTopColor: "#0072BA", textAlign: "center",
                            display: "flex", alignItems: "center", justifyContent: "center"
                        }}>
                            <p style={{
                                fontFamily: "Nunito", fontSize: 15, fontWeight: "normal", color: "#000",
                                whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "100%",
                                margin: 0, padding: 0
                            }}>На жаль, у вас немає попередніх записів</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
